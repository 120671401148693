import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';

const Jobs = () => (
  <>
    <Helmet>
      <title>{i18n.footerJobs}</title>
      <meta name="description" content={i18n.jobs} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
    {/* banner */}
    <Box sx={{
      backgroundColor: 'primary.dark',
      height: '250px',
      marginBottom: '10px',
    }}
    >
      <h1>{i18n.usBanner}</h1>
    </Box>
    {/* 4 valores */}
    <Box sx={{
      backgroundColor: 'secondary.main',
      height: '250px',
      marginBottom: '10px',
    }}
    >
      <h1>{i18n.jobsValues}</h1>
    </Box>
    {/* Por qué trabajar con Credicuotas */}
    <Box sx={{
      backgroundColor: '#ff9800',
      height: '250px',
      marginBottom: '10px',
    }}
    >
      <h1>{i18n.jobsWhy}</h1>
    </Box>
    {/* Qué es Credicuotas */}
    <Box sx={{
      backgroundColor: '#ff9800',
      height: '250px',
      marginBottom: '10px',
    }}
    >
      <h1>{i18n.jobsWhat}</h1>
    </Box>
    {/* Contacto RRHH */}
    <Box sx={{
      backgroundColor: '#ff9800',
      height: '250px',
      marginBottom: '10px',
    }}
    >
      <h1>{i18n.jobsContact}</h1>
    </Box>
  </>
);

export default Jobs;
