import {
  Box, Grid, Typography, Button,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import contactBCRA from '../../images/infoFinancialUser/contactate-con-bcra.jpg';
import unsubsribeProduct from '../../images/infoFinancialUser/baja-productos-financieros.jpg';
import whatsappLogo from '../../images/contact/whatsapp.svg';
import email from '../../images/contact/email.svg';
import phone from '../../images/contact/phone.svg';
import atencionCliente from '../../assests/images/financeUserInformation/atencionCliente.png';
import tasas from '../../assests/images/financeUserInformation/signosTasas.png';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import Banner from '../common/Banner';
import '../../assests/css/financeUserInformationStyle.css';

const FinanceUserInformation = () => (
  <>
    <Helmet>
      <title>{i18n.metaTitleUserInformation}</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content={i18n.metaDescriptionUserInformation} />
      <meta name="robots" content="noindex follow" />
    </Helmet>
    {/* banner */}
    <Box mt={10}>
      <Banner title={i18n.financeUserInformation} backgroundImage={bannerImage} />
    </Box>
    {/* canales de contacto */}
    <Box className="flexCenter">
      <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxConsumer" mt={8}>
        {i18n.infoContact}
      </Typography>
    </Box>
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Box className="boxChannelsHelp spaceBetween">
          <Grid container>
            <Grid item xs={12} lg={3} className="lettersMagentaNew flexCenter">
              <Box className="flexCenter">
                <Typography variant="h3" fontWeight={800} className="lettersMagentaNew">
                  {i18n.helpChannels}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <a href="http://wa.me/+5491164575091" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box className="flexCenter marginBoxContact">
                  <img src={whatsappLogo} alt="whatsapp" className="imgContact" />
                  <Box className="flexCenter widthBoxTypography" mt={2}>
                    <Typography variant="h5" fontWeight={500} className="lettersGreyParapgraph">
                      {i18n.infoWhatsapp}
                    </Typography>
                  </Box>
                  <Typography variant="body1" className="lettersVersionTwo">{i18n.infoWhatsappNumber}</Typography>
                </Box>
              </a>
            </Grid>
            <Grid item xs={12} lg={3}>
              <a
                href="mailTo: clientes@credicuotas.com.ar"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: '#383838' }}
              >
                <Box className="flexCenter marginBoxContact">
                  <img src={email} alt="email" className="imgContact" />
                  <Box className="flexCenter widthBoxTypography" mt={2}>
                    <Typography variant="h5" fontWeight={500} className="lettersGreyParapgraph">
                      {i18n.infoMail}
                    </Typography>
                  </Box>
                  <Typography variant="body1" className="lettersVersionTwo">{i18n.infoMailClient}</Typography>
                </Box>
              </a>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box className="flexCenter marginBoxContact" mb={2}>
                <img src={phone} alt="phone" className="imgContact" />
                <Box className="flexCenter widthBoxTypography" mt={2}>
                  <Typography variant="h5" fontWeight={500} className="lettersGreyParapgraph">
                    {i18n.infoTelephone}
                  </Typography>
                </Box>
                <Typography variant="body1" className="lettersVersionTwo">{i18n.infoTelephoneNumber}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={1} />
    </Grid>
    {/* Ayuda personalizada */}
    <Box mt={1} className="boxLegalNotifications">
      <Grid container>
        <Grid item xs={1} lg={0.1} />
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} lg={6} className="flexCenter">
              <Box className="marginNotifications">
                <img src={atencionCliente} alt="chica de atencion al cliente" className="imgNotifications" />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} className="flexCenter marginUp">
              <Box className="textLeft">
                <Box className="widthLess">
                  <Typography variant="h3" fontWeight={700} className="lettersGrey">
                    {i18n.infoFirstContactUs}
                  </Typography>
                </Box>
                <Box mt={2.5}>
                  <Typography variant="p" fontSize={18}>
                    {i18n.infoFirstPerson}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="p" fontSize={18}>
                    {i18n.infoSecondPerson}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="p" fontSize={18}>
                    {i18n.infoClientMail}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="p" fontSize={18}>
                    {i18n.infoTelephoneContactNumber}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="p" fontSize={18}>
                    {i18n.infoAddress}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} lg={1.9} />
      </Grid>
    </Box>
    {/* Tasas */}
    <Box className="boxBranch">
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Box className="flexCenter">
            <Box className="boxBranchInfo" mt={8}>
              <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxHelpTypography">
                {i18n.infoFees}
              </Typography>
              <Box className="spaceBetween boxTasas">
                <Button variant="contained" className="ButtonMagenta buttonMoreInfo">
                  <a
                    href="/TasaVigentesPrestamosPersonale.pdf"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    rel="nofollow"
                    target="_blank"
                  >
                    {i18n.homeMoreInformation}
                  </a>
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box className="flexCenter">
            <img src={tasas} alt="SignosPesos" className="imgTasas" />
          </Box>
        </Grid>
      </Grid>
    </Box>
    {/* Banco central */}
    <Box className="spaceBetween">
      <Box className="flexCenter">
        <Typography variant="h3" component="h2" fontWeight={700} className="lettersGrey widthBoxConsumer" mt={2}>
          {i18n.infoRights}
        </Typography>
      </Box>
      <Grid container className="spaceBetween">
        <Grid item xs={12} md={12}>
          <img
            src={contactBCRA}
            alt="Contactate con el Banco Central para consultas"
            style={{ maxWidth: '337px', width: '100%' }}
          />
          <img
            src={unsubsribeProduct}
            alt="Explicación de la baja del servicio financiero"
            style={{ maxWidth: '337px', width: '100%' }}
          />
        </Grid>
      </Grid>
    </Box>
    {/* protección a usuarios financieros */}
    <Box className="spaceBetween" mb={3}>
      <Typography variant="p">{i18n.infoFirstP}</Typography>
    </Box>
  </>
);

export default FinanceUserInformation;
