import { useState, useEffect } from 'react';
import {
  Box, Button, Slider, Typography,
} from '@mui/material';
// import { styled } from '@mui/material/styles';
import { getCalculatorData } from '../../api/services';
import i18n from '../common/i18n';
import matrixResponse from './matrixResponse.json';
import '../../assests/css/wtStyle.css';
import underline from '../../assests/images/wt/underline.svg';
import {
  ORIGINACION_WT_THIRD_STEP, getEnv,
} from '../../api/env';

const WtCalculator = () => {
  const [amounts, setAmounts] = useState([]);
  const [matrix, setMatrix] = useState({});
  const [terms, setTerms] = useState([]);
  const minimumValue = amounts && amounts[0];
  const maximumValue = amounts && (amounts[amounts.length - 1]);
  const step = amounts && (amounts[1] - amounts[0]);
  const [value = undefined, setValue] = useState(undefined);
  const ORIGINACION_WT_THIRD_STEP_LINK = getEnv(ORIGINACION_WT_THIRD_STEP);

  /* const WhiteSlider = styled(Slider)({
    color: 'white',
  }); */

  const getCalculator = async () => {
    try {
      const { data } = await getCalculatorData();
      setAmounts(data.amounts);
      setMatrix(data.matrix);
      setTerms(data.terms);
      setValue(data.amounts && (data.amounts[Math.round((data.amounts && data.amounts.length - 1) / 2)]));
    } catch (error) {
      console.error('There was an error!', error);
      setAmounts(matrixResponse.amounts);
      setMatrix(matrixResponse.matrix);
      setTerms(matrixResponse.terms);
    }
  };

  useEffect(() => {
    getCalculator();
  }, []);

  const amountSelectedIndexPosition = amounts.indexOf(value);
  const installmentsAmountList = matrix[amountSelectedIndexPosition];
  const termsList = terms[amountSelectedIndexPosition];

  // get valor cuota
  const amountLastList = installmentsAmountList && installmentsAmountList.length - 1;
  const installmentAmountSelected = installmentsAmountList && installmentsAmountList[amountLastList];

  // get the terms
  const termsLastList = termsList && termsList.length - 1;
  const termsLastListSelected = termsList && termsList[termsLastList];

  function getPaymentDate() {
    const dateNow = new Date();
    const day = dateNow.getDate();
    let plusMonth = 1;
    if (day >= 21) {
      plusMonth += 1;
    }
    dateNow.setDate(1);
    dateNow.setMonth(dateNow.getMonth() + plusMonth);
    const dueMonth = dateNow.toLocaleString('es-ES', { month: 'long' });
    return `6 de ${dueMonth.toUpperCase()}`;
  }

  return (
    <Box className="resultsCalculator">
      <Box className="boxResultsCalculator">
        {
          value !== undefined && (
            <>
              <Box className="boxSlider">
                <Typography variant="h5" fontWeight={500} className="contentBoxSlider">
                  {i18n.wtHowMany}
                </Typography>
                <Typography variant="h3" fontWeight={700} className="contentBoxSlider">
                  {`$${value}`}
                </Typography>
                <Slider
                  className="slider"
                  aria-label={i18n.wtCalculator}
                  style={{ color: '#DA0067' }}
                  onChange={(e, item) => {
                    setValue(item);
                  }}
                  value={value}
                  step={step}
                  min={minimumValue}
                  max={maximumValue}
                />
              </Box>
              <Typography variant="p" className="pCalculator pAlign">{i18n.wtPriceFrom}</Typography>
              <Typography variant="h5" fontWeight={700}>{`$${installmentAmountSelected}`}</Typography>
              <img src={underline} alt="underline" />
              <Box className="lineResults">
                <Typography variant="p" className="pCalculator">{i18n.wtQuantity}</Typography>
                <Typography variant="h6" className="resultMargin">{termsLastListSelected}</Typography>
              </Box>
              <hr />
              <Box className="lineResults">
                <Typography variant="p" className="pCalculator">{i18n.wtFirstExpiration}</Typography>
                <Typography variant="h6" className="resultMargin">{getPaymentDate()}</Typography>
              </Box>
              <a
                href={ORIGINACION_WT_THIRD_STEP_LINK}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Button variant="contained" className="ButtonMagenta buttonCredit">
                  {i18n.wtButtonCredit}
                </Button>
              </a>
              <Box mt={0.75}>
                <Typography variant="subtitle2">{i18n.wtLegal}</Typography>
              </Box>
            </>
          )
  }
      </Box>
    </Box>
  );
};

export default WtCalculator;
