// import logoCredicuotas from '../../images/logoCredicuotas.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import i18n from './i18n';
import '../../assests/css/footerStyle.css';
import imageBcra from '../../assests/images/footer/BCRA_logo.svg.png';
import imageCnv from '../../assests/images/footer/cnv.png';
import imageDataFiscal from '../../assests/images/footer/data-fiscal.png';
import imagePdp from '../../assests/images/footer/pdp.png';
import imageRenaper from '../../assests/images/footer/renaper.png';
import imageSid from '../../assests/images/footer/sid.png';
import imageFinanceUser from '../../images/infoFinancialUser/usuario_financiero.png';

/* eslint "arrow-body-style": "off" */
const FooterLogos = () => {
  return (
    <Box className="boxFooter">
      <Box className="boxHr">
        <hr />
        <p className="pBox">
          {i18n.footerLegalText}
        </p>
      </Box>
      <Grid container className="gridImages">
        <Grid item xs={6} md={1.7}>
          <img
            src={imageCnv}
            alt={i18n.footerCnv}
            className="imageCnv"
          />
        </Grid>
        <Grid item xs={6} md={1.7}>
          <img
            src={imageRenaper}
            alt={i18n.footerRenaper}
            className="imageRenaper"
          />
        </Grid>
        <Grid item xs={6} md={1.7}>
          <img
            src={imageSid}
            alt={i18n.footerSid}
            className="imageCid"
          />
        </Grid>
        <Grid item xs={6} md={1.7}>
          <img
            src={imagePdp}
            alt={i18n.footerPdp}
            className="imagePdp"
          />
        </Grid>
        <Grid item xs={6} md={1.7}>
          <img
            src={imageBcra}
            alt={i18n.footerBcra}
            className="imageBcra"
          />
        </Grid>
        <Grid item xs={6} md={1}>
          <img
            src={imageDataFiscal}
            alt={i18n.footerDataFiscal}
            className="imageDataFiscal"
          />
        </Grid>
        <Grid item xs={6} md={1.7}>
          <a
            href="https://www.bcra.gob.ar/BCRAyVos/Usuarios_Financieros.asp"
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <img
              src={imageFinanceUser}
              alt={i18n.footerUserInformation}
              className="imageUserInformation"
            />
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterLogos;
