import {
  Grid, Box, Button, Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import i18n from '../common/i18n';
import '../../assests/css/branches.css';
import logoCredicuotas from '../../images/logoCredicuotas.png';
import iconConsulta from '../../assests/images/icons/consulta.svg';
import iconChat from '../../assests/images/icons/chat.svg';
import iconVerificado from '../../assests/images/icons/verified-user.svg';
import iconWhatsapp from '../../assests/images/icons/whatsapp.svg';
import LegalFooter from '../common/LegalFooter';
/* eslint "arrow-body-style": "off" */
const Branches = () => {
  return (
    <>
      <Helmet>
        <title>{i18n.branchesName}</title>
        <meta name="description" content={i18n.branchesName} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <header style={{ backgroundColor: 'white' }}>
        <Grid container>
          <Grid item xs={10} md={2} className="gridLogoHeader">
            <Link
              to={{ pathname: '/' }}
              style={{ textDecoration: 'none' }}
            >
              <img
                src={logoCredicuotas}
                alt={i18n.credicuotas}
                className="logoHeader"
              />
            </Link>
          </Grid>
        </Grid>
      </header>
      {/* Banner */}
      <Grid container className="bannerBind" mt={11}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h4" component="h1" fontWeight={700} color="#383838" className="branchTitle">
              {i18n.branchesTitle}
            </Typography>
            <a
              href="https://api.whatsapp.com/send?phone=5491164575091&text=Hola%2C%20me%20gustar%C3%ADa%20averiguar%20por%20un%20cr%C3%A9dito%20con%20Credicuotas"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit', paddingTop: '2%' }}
            >
              <Button variant="contained" className="ButtonVerde">
                <img src={iconWhatsapp} alt="WhatsApp Credicuotas" className="logoWpp" />
                {i18n.branchesButton}
              </Button>
            </a>
            <Box className="textClick">
              <Box>
                <Typography variant="p" className="branchesAvailable">{i18n.branchesMoney}</Typography>
              </Box>
              <Box className="spaceParagraph">
                <Typography variant="p" className="branchesClic">{i18n.branchesClic}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* WhatsApp Business */}
      <Grid container className="whatsappBusiness">
        <Grid item xs={12}>
          <Box>
            <img src={iconVerificado} alt="success" className="imgIcon" />
            <Box className="spaceParagraph">
              <Typography variant="p" className="TextBusiness">{i18n.branchesWppBusiness}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* Steps */}
      <Box className="boxContainerIcons">
        <Grid container>
          <Grid item xs={12} md={6} className="gridIcons">
            <Box className="boxBranches">
              <img src={iconConsulta} alt="consulta" className="branchesIcons" />
              <Box className="spaceBox">
                <Typography variant="p" className="branchesTextIcons">{i18n.branchesAvailable}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="gridIcons">
            <Box className="boxBranches">
              <img src={iconChat} alt="telefono con notificacion" className="branchesIcons" />
              <Box className="spaceBox">
                <Typography variant="p" className="branchesTextIcons">{i18n.branchesChat}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid>
        <Box className="branchesWait">
          <p>{i18n.branchesWait}</p>
        </Box>
      </Grid>
      <LegalFooter />
    </>
  );
};

export default Branches;
