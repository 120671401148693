import {
  Grid, Box, Button, Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import logoCredicuotas from '../../images/logoCredicuotas.png';
import '../../assests/css/whatsapp.css';
import iconConsulta from '../../assests/images/icons/consulta.svg';
import iconChat from '../../assests/images/icons/chat.svg';
import iconSelfie from '../../assests/images/icons/selfie.svg';
import iconVerificado from '../../assests/images/icons/verified-user.svg';
import iconWhatsapp from '../../assests/images/icons/whatsapp.svg';
import LegalFooter from '../common/LegalFooter';
/* eslint "arrow-body-style": "off" */
const Whatsapp = () => {
  return (
    <>
      <Helmet>
        <title>{i18n.wppName}</title>
        <meta name="description" content={i18n.wppName} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <header style={{ backgroundColor: 'white' }}>
        <Grid container>
          <Grid item xs={10} md={2} className="gridLogoHeader">
            <img
              src={logoCredicuotas}
              alt={i18n.credicuotas}
              className="logoHeader"
            />
          </Grid>
        </Grid>
      </header>
      {/* Banner */}
      <Grid container className="banner" mt={11}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h3" component="h1" fontWeight={700} color="#383838" className="WppTitle">
              {i18n.wppTitle}
            </Typography>
            <a
              href="https://api.whatsapp.com/send?phone=5491164575091&text=Hola%2C%20me%20gustar%C3%ADa%20averiguar%20por%20un%20cr%C3%A9dito%20con%20Credicuotas"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit', paddingTop: '2%' }}
            >
              <Button variant="contained" className="ButtonVerde buttonWpp">
                <img src={iconWhatsapp} alt="WhatsApp Credicuotas" className="logoWpp" />
                {i18n.wppButton}
              </Button>
            </a>
            <Box className="textClick">
              <Typography>{i18n.wppClickButton}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* WhatsApp Business */}
      <Grid container className="wppBusiness">
        <Grid item xs={12}>
          <Box>
            <img src={iconVerificado} alt="success" className="imgIcon" />
            <Box>
              <Typography variant="p" className="TextBusiness">{i18n.wppBusiness}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* Datos */}
      <Box className="boxContainerIcons">
        <Grid container>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxIcons">
              <Box className="centerIcons">
                <img src={iconConsulta} alt="success" className="wppIcons" />
                <Box className="spaceParagraph">
                  <Typography variant="h6" className="textIcons">{i18n.wppAsk}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxIcons">
              <img src={iconChat} alt="success" className="wppIcons" />
              <Typography variant="h6" className="textIcons">{i18n.wppChat}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxIcons">
              <img src={iconSelfie} alt="success" className="wppIcons" />
              <Typography variant="h6" className="textIcons">{i18n.wppPhoto}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LegalFooter />
    </>
  );
};

export default Whatsapp;
