/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
import routes from '../navigation/routes';

const i18n = {
  credicuotas: 'Credicuotas',
  commerce: 'Comercios',
  faq: 'Preguntas frecuentes',

  // Format handlers
  invalidFormat: 'Formato Inválido',
  valueRequired: '${0} requerido',
  onlyNumbersAllowed: 'Solo ingresar números',
  maxLenght: 'Longitud maxima ${0}',

  // Header
  headerCommerce: 'Comercios',
  headerClient: 'Clientes',
  headerMenuHome: { title: 'Inicio', route: routes.home },
  headerMenuUs: { title: 'Nosotros', route: routes.us },
  headerMenuBlog: { title: 'Blog', route: routes.blog },
  headerMenuPhysicalCommerce: { title: 'Comercios físicos', route: routes.physicalCommerce },
  // headerMenuBuyNowPayLater: { title: 'Tiendas online', route: routes.buyNowPayLater },
  headerMenuWt: { title: 'Pedí tu préstamo', route: routes.wt },
  headerMenuHelp: { title: 'Ayuda', route: routes.help },
  headerWhereToBuyTitle: 'Dónde comprar',

  // Metadata
  metaTitleHome: 'Prestamos Online | CrediCuotas',
  metaTitleBlog: 'Comunidad - Préstamos online | Credicuotas',
  metaTitleUserInformation: 'Información a usuarios financieros',
  metaTitleCancel: 'Cancelá tu préstamo',
  metaTitleWc: 'Comprá en comercios',
  metaDescriptionHome: 'Pedí tu préstamo online y pagalo en cuotas fijas! Te lo acreditamos en el mismo día. Ingresá para ver cuánto te prestamos. Credicuotas',
  metaDescriptionHelp: 'Acá vas a encontrar las respuestas a tus dudas sobre nuestros productos.',
  metaDescriptionCancel: 'Te explicamos como cancelar tu prestamo solicitado, paso a paso.',
  metaDescriptionUserInformation: 'Información y medios de contacto para el usuario financiero',
  metaDescriptionUs: 'Somos una compañía financiera. Ofrecemos préstamos personales online, sin vueltas y sin letra chica.',
  metaDescriptionWc: 'Comprá lo que quieras y pagá en cuotas sin tarjeta de crédito en comercios adheridos.',
  metaDescriptionConsumerProtection: 'Información de utilidad para el consumidor.',
  metaDescriptionBlog: 'Novedades sobre el sector financiero',

  // Footer
  footerWe: 'Nosotros',
  footerPersonalLoans: 'Préstamos personales',
  footerBuyOnline: 'Comprá online',
  footerBuyCommerce: 'Comprá en comercios',
  footerSucursal: 'Sucursal',
  footerHelp: 'Ayuda',
  footerCommerce: 'Soy comercio',
  footerClient: 'Soy cliente',
  footerCommunity: 'Comunidad',
  footerBlog: 'Blog',
  footerWorkWithUs: 'Trabajá con nosotros',
  footerInvestors: 'Investors',
  footerPrivacy: 'Políticas de privacidad',
  footerConsumerDefense: 'Defensa al consumidor',
  footerContract: 'Contrato Adhesión. Ley 24240',
  financeUserInformation: 'Información a usuarios financieros',
  footerConditions: 'Términos y condiciones',
  footerRepentanceButton: 'Botón de arrepentimiento',
  footerUnsuscribeButton: 'Botón de baja',
  footerLegalText: 'La oferta y otorgamiento del préstamo están sujetos a evaluación crediticia, comercial y de riesgos de CREDICUOTAS CONSUMO S.A. La TNA varía de acuerdo al perfil crediticio del solicitante. Todos los préstamos son en pesos argentinos a tasa fija, con cuotas mensuales y consecutivas.',
  footerCuitText: 'Credicuotas pertenece a CREDICUOTAS CONSUMO S.A. CUIT 30-71091158-0, C.A.B.A. Argentina Copyright Credicuotas 2022',
  footerFacebook: 'Facebook',
  footerInstagram: 'Instagram',
  footerLinkedin: 'Linkedin',
  footerYoutube: 'Youtbe',
  footerUserInformation: 'Información al usuario financiero',
  footerCnv: 'CNV',
  footerRenaper: 'Renaper',
  footerSid: 'SID',
  footerPdp: 'PDP',
  footerBcra: 'BCRA',
  footerDataFiscal: 'Data Fiscal',

  // Pages:

  // Home:
  homeBnpl: 'Comprá online en cuotas sin tarjeta',
  homeWt: 'PRÉSTAMOS PERSONALES',
  homePhysicalCommerce: 'Comprá en cuotas en comercios',
  homeCreditNow: '¡Préstamos al instante!',
  homeCreditNowSubtitle: 'Pedí efectivo 100% online, recibilo en minutos y pagalo en cuotas fijas.',
  homeWithUs: '¿Por qué elegirnos?',
  homeWhatNeed: 'Qué necesito',
  homeDni: 'Dni',
  homeSelfie: 'Selfie',
  homeValidation: 'Validación',
  homeForWhat: '¿Para qué lo podés usar?',
  homeDesire: 'Obtengo lo que deseo',
  homeSloganWt: 'Préstamos online en el acto: recibí efectivo en tu cuenta en minutos. Simples, rápidos y seguros.',
  homeCTAWt: 'Pedilo YA',
  homeSloganBnpl: '¡Entrá a las tiendas online adheridas y comprá productos en cuotas sin tarjeta de crédito!',
  homeMoreInformation: 'Más información',
  homeSloganPhysicalCommerce: 'Comprá en comercios físicos adheridos y pagá en cuotas fijas. Te financiamos tu compra con un préstamo en el acto.',
  homeWeAreSimple: 'SOMOS SIMPLES',
  homeSloganSimple: 'Brindamos préstamos personales 100% online con mínimos requisitos en solo minutos.',
  homeWeFinanceYou: 'FINANCIAMOS EN CUOTAS FIJAS',
  homeSloganWeFinanceYou: 'Otorgamos préstamos hasta 36 cuotas fijas para que compres lo que quieras.',
  homeThinkOnYou: 'PENSAMOS EN VOS',
  homeSloganThinkOnYou: 'Más préstamos pedís, más beneficios te damos con mayores montos y tasas más bajas.',
  homeRequirementsCredit: '¿Qué necesito para recibir un préstamo?',
  homeRequirementAdult: 'Ser mayor de 18 años y tener DNI Argentino',
  homeRequirementTelephone: 'Tener un número de celular y un mail particular',
  homeRequirementWhite: 'Debés tener ingresos en blanco',
  homeHowToDo: '¿Cómo saco un préstamo?',
  homeEnterYourData: 'Ingresá tus datos',
  homeValidateIdentity: 'Validá tu identidad',
  homeReceive: 'Recibí lo que deseas',
  homeBuyMoto: 'Comprá tu moto',
  homeCellphone: 'Recargá tu celular',
  homeBuyElectro: 'Comprá tus electros',
  homePayService: 'Pagá tus servicios',
  homeFirstQuestion: '¿Qué servicio online ofrece Credicuotas?',
  homeFirstAnswerOne: 'Somos una compañía financiera 100% online que ofrece préstamos personales. ',
  homeAnswerCTAOrigination: 'Ingresá a nuestra web ',
  homeFirstAnswerTwo: 'y te guiamos paso a paso cómo sacar el tuyo.',
  homeSecondQuestion: '¿Cómo solicitar un préstamo personal en Credicuotas?',
  homeSecondAnswerOne: 'Solamente necesitás una foto de frente y dorso de tu DNI original y una selfie. ',
  homeSecondAnswerTwo: 'y hacelo vos mismo.',
  homeThirdQuestion: '¿Cuánto dinero online puedo solicitar en Credicuotas?',
  homeThirdAnswer: 'El monto de tu préstamo será determinado mediante el resultado de tu análisis crediticio. Si sos cliente Credicuotas y estás al día con tus pagos, tu disponible para préstamos aumenta. ¿Querés solicitar uno? ',
  homeAnswerCTAOriginationHere: 'Pedilo aquí',
  homeFourQuestion: '¿Cómo recibo el dinero de mis préstamos online con Credicuotas?',
  homeFourAnswer: 'Una vez que el crédito es aprobado, vas a disponer de los fondos en tu cuenta bancaria o digital o en los canales que tenemos habilitados para retirar efectivo.',
  homeMoreQuestion: '¿Tenés más dudas? ',
  homeLinkHelp: 'Ingresa acá',

  // Us
  usTitle: 'Nosotros',
  usSubtitle: 'Te prestamos sin vueltas y sin letra chica.',
  usWhat: '¿Qué hacemos?',
  usCompany: 'Somos una compañía financiera',
  usWhatWeDo: 'Ofrecemos préstamos personales online',
  usSimple: 'Simples y rápidos',
  usAccreditation: 'Acreditación inmediata',
  usShop: 'Para compras en comercios',
  usGive: 'En Credicuotas otorgamos:',
  usFirstBenefit: 'Préstamos online con acreditación inmediata',
  usSecondBenefit: 'Préstamos para realizar compras en tiendas virtuales al momento de realizar la compra para financiar sin tarjeta de crédito.',
  usThirdBenefit: 'Préstamos en comercios físicos para financiar tu compra al instante',
  usValueTitle: 'Propuesta de valor',
  usValueTextFirstOne: 'Somos la primera ',
  usValueTextFirstTwo: 'fintech ',
  usValueTextFirstThree: 'de Argentina y  sabemos que el acceso al crédito es clave para concretar proyectos y democratizar efectivamente la economía.',
  usValueTextSecondOne: 'Por eso, brindamos un servicio ',
  usValueTextSecondTwo: 'ágil ',
  usValueTextSecondThree: 'y ',
  usValueTextSecondFour: 'simple',
  usValueTextSecondFive: ', a través de la más ',
  usValueTextSecondSix: 'tecnología',
  usValueTextSecondSeven: ', que permite gestionar un préstamo de manera instantánea, para que cada usuario tenga la oportunidad de concretar sus sueños.',
  usHistory: 'Nuestra historia',
  usHistoryFirstYear: '2010-2011',
  usHistoryFirstText: 'Proceso de incubación',
  usHistorySecondYear: '2012',
  usHistorySecondTextOne: 'Nace Credicuotas',
  usHistorySecondTextTwo: 'Buenos Aires - Tucumán',
  usHistoryThirdYear: '2013',
  usHistoryThirdText: 'Grupo Bind adquiere Credishopp S.A.',
  usHistoryFourthYear: '2014',
  usHistoryFourthTextOne: 'Primera aplicación web para ',
  usHistoryFourthTextTwo: 'comercios y préstamos web',
  usHistoryFifthYear: '2016',
  usHistoryFifthTextOne: 'Poincenot Studio',
  usHistoryFifthTextTwo: 'Partnership con Poincenot Studio para la transformación y aceleración digital',
  usHistorySixthYear: '2017',
  usHistorySixthTextOne: '¡Credicuotas crece! ',
  usHistorySixthTextTwo: 'Ingresan nuevos colaboradores',
  usHistorySixthTextThree: 'Nueva aplicación para Comercios.',
  usHistorySeventhYear: '2018',
  usHistorySeventhText: 'Nueva aplicación web para préstamos personales.',
  usHistoryEighthYear: '2019',
  usHistoryEighthText: ' Renovamos el producto web comercios',
  usHistoryNinethYear: '2020',
  usHistoryNinethText: 'Partnership con Honda y Uber',
  usHistoryTenthYear: '2021',
  usHistoryTenthTextOne: 'Partnership con MercadoLibre para financiar motos',
  usHistoryEleventhYear: '2022',
  usHistoryEleventhTextOne: 'Emitimos Obligaciones Negociables por más de $10 MM.',
  usWho: '¿Quienes somos?',
  usWhoText: 'Contamos con un equipo multidisciplinario liderado por profesionales de extensa trayectoria en el mercado financiero y amplios conocimientos en la transformación digital de la industria.',
  usEzequiel: 'Ezequiel Weisstaub',
  usDiego: 'Diego Manobla',
  usCeo: 'CEO',
  usCro: 'CRO',
  usGroup: 'Nuestro grupo',
  usPoincenot: 'Poincenot',
  usBind: 'Bind',
  usLogoLinkedIn: 'LinkedIn',

  // PhysicalCommerce
  physicalLogo: 'Logo de marca',
  physicalHowWorks: 'Como funciona',
  physicalFind: 'Descubri tu comercio más cercano',

  // BuyNowPayLater
  bnplTitle: 'Comprá ahora, pagá después',
  bnplSubtitle: 'Elegí Credicuotas como medio de pago en las tiendas digitales adheridas para pagar en cuotas ¡sin tarjeta!',
  bnplHow: '¿Cómo hacer compras online con Credicuotas?',
  bnplFirstRequirement: 'Llená tu carrito en nuestras tiendas virtuales adheridas',
  bnplSecondRequirement: 'Elegí Credicuotas como medio de pago',
  bnplThirdRequirement: 'Completá tus datos personales',
  bnplFirstQuestion: '¿Cómo funciona el método compre ahora y pague después?',
  bnplFirstAnswerOne: '. Comprá en nuestras tiendas online adheridas y seleccioná Credicuotas como medio de pago.',
  bnplFirstAnswerTwo: '. Elegí el plan de pagos que sea más conveniente para vos.',
  bnplFirstAnswerThree: '. Las cuotas se pagan mensualmente.',
  bnplSecondQuestion: '¿Puedo comprar ahora y pagar después con Credicuotas?',
  bnplSecondAnswer: 'Sí, en nuestra red de tiendas online adheridas podés pagar con Credicuotas de forma rápida y simple.',
  bnplThirdQuestion: '¿Puedo pagar en cuotas sin tarjeta de crédito?',
  bnplThirdAnswer: 'Sí, con Credicuotas podés pagar tus compras en cuotas con tu tarjeta de débito. La primera cuota la pagás el mes siguiente.',
  bnplFourthQuestion: '¿Qué productos puedo comprar con el sistema BNPL?',
  bnplFourthAnswer: 'Podés comprar todos los productos disponibles en nuestras tiendas adheridas.',

  // Commerce
  commerceBenefits: 'Beneficios',
  commerceCharts: 'Charts',
  commercePartners: 'Partners',
  commerceHowWorks: 'Como funciona',

  // Wt
  wtSubtitle: '¡Préstamos al instante! Pedí efectivo 100% online, recibilo en minutos y pagalo en cuotas fijas.',
  wtCalculator: 'Calculadora',
  wtHowWorks: '¿Como solicitar un préstamos online?',
  wtRequiriments: 'Los requisitos son muy simples!',
  wtFaq: 'Preguntas o datos básicos',
  wtCalculate: 'Calculá tu cuota',
  wtHowMany: '¿Cuánto querés pedir?',
  wtPriceFrom: 'Valor cuota desde:',
  wtQuantity: 'Cantidad de cuotas',
  wtFirstExpiration: 'Primer Vencimiento',
  wtButtonCredit: 'Pedilo ahora',
  wtLegal: '* Sujeto a verificación crediticia',
  wtFirstQuestion: '¿Puedo pedir préstamos online en Credicuotas?',
  wtFirstAnswerOne: 'Sí, de forma simple y rápida podés pedir tu ',
  wtFirstAnswerCTA: 'préstamo aquí',
  wtFirstAnswerTwo: ' y pagalo en cuotas fijas.',
  wtSecondQuestion: '¿Cuáles son los requisitos para solicitar un préstamo online?',
  wtSecondAnswerOne: 'Sólo es necesario estar radicado en Argentina con DNI argentino y ser mayor de 18 años, disponer de ingresos demostrables y cuenta bancaria. ',
  wtSecondAnswerTwo: ' y el sistema te indicará el monto que podés solicitar.',
  wtThirdQuestion: '¿Cómo se pagan las cuotas de los préstamos 100% online?',
  wtThirdAnswer: 'Las cuotas pueden ser abonadas por distintos medios de pago: transferencia bancaria o depósito bancario, tarjeta de débito, débito directo en cuenta o en medios de cobro como: Rapipago, Pago Fácil, Cobro Express y Mercado Pago. ',
  wtFourthQuestion: '¿Cuánto tarda Credicuotas en otorgar préstamos personales con DNI?',
  wtFourthAnswer: 'Una vez que el crédito es aprobado, vas a disponer de los fondos en tu cuenta bancaria en minutos.',

  // Wc
  wcTitle: 'Comprá en comercios',
  wcSubtitle: 'Comprá lo que quieras y pagá en cuotas sin tarjeta de crédito en comercios adheridos.',
  wcHow: '¿Cómo saco un préstamo en comercios?',
  wcComeCommerce: 'Acercate a un comercio adherido',
  wcVendorData: 'Brindale tus datos al vendedor',
  wcProduct: 'Llevate tu producto',
  wcOurCommerce: 'Nuestros principales comercios adheridos',
  wcRequirements: '¿Qué necesito para recibir un préstamo?',
  wcCommerce: 'Comprá en nuestros comercios físicos adheridos',
  wcOwnerCommerce: '¿Tenés un comercio y querés formar parte de Credicuotas?',
  wcWriteUs: 'Escribinos a:',
  wcCommerceMail: 'comercios@credicuotas.com.ar',
  wcAreas: '¿Qué rubros trabajamos?',
  wcMoto: 'Motos y bicicletas',
  wcElectro: 'Electrodomésticos y tecnología',
  wcFurniture: 'Muebles y colchones',
  wcInstruments: 'Instrumentos musicales',
  wcFirstQuestion: '¿Cómo hago para adherirme como comercio?',
  wcFirstAnswer: 'Si tu negocio está en el rubro de electrodomésticos, electrónica en general, motocicletas, bicicletas, instrumentos musicales, hogar, colchones y muebles, podes comunicarte con nosotros al mail comercios@credicuotas.com.ar y dejanos los datos de tu comercio para que te contactemos.',
  wcSecondQuestion: '¿Cómo funcionan los préstamos en comercios?',
  wcSecondAnswer: 'El cliente compra el producto que desea en el comercio adherido a Credicuotas, y paga con un prestamo personal en cuotas fijas. El comercio recibe el monto total de la compra sin ningún tipo de riesgo.',
  wcThirdQuestion: '¿Qué rubros de comercio no están habilitados para adherirse?',
  wcThirdAnswer: 'Por el momento no estamos trabajando con comercios de indumentaria, calzados, casas de indumentaria y calzado de deporte, supermercados, decoración, librerías, pinturerías y materiales de construcción.',
  wcFourthQuestion: '¿Por qué me conviene ofrecer los préstamos de Credicuotas como medio de pago?',
  wcFourthAnswer: 'Está probado que al ofrecer mayores alternativas de pago al cliente, el volumen de ventas para el comercio se incrementa. Credicuotas es una excelente opción para los clientes que quieren pagar en cuotas fijas sus consumos.',
  wcFindCommerce: 'Descubrí el comercio más cercano',
  wcProvince: 'Provincia',
  wcSearch: 'Buscar por calle, localidad o nombre comercial',

  // Help
  helpSubtitle: 'Acá vas a encontrar las respuestas a tus dudas sobre nuestros productos.',
  helpProducts: '¿Sobre qué productos tenés dudas?',
  helpAboutCredicuotas: 'Sobre Credicuotas',
  helpWc: 'Comercios físicos',
  helpWt: 'Préstamos personales',
  helpBnpl: 'Tienda virtual',
  helpCommerce: 'Soy Comercio',
  helpWhoWeAre: '¿Quiénes somos?',
  helpAnswerWhoWeAre: 'Somos una compañía financiera 100% online que ofrece préstamos personales online a individuos. Contamos con un equipo multidisciplinario, liderado por profesionales de extensa trayectoria en el mercado financiero y amplios conocimientos en colocación de préstamos y transformación digital de la industria. Lideramos el segmento de préstamos personales, con más de 8 años en el mercado financiero. CREDICUOTAS CONSUMO SA con CUIT 30-71091158-0 con domicilio en Arenales 707, piso 7 (CABA) es la razón social de la marca Credicuotas.',
  helpWhoWeAreSecondQuestion: '¿Qué ofrecemos?',
  helpAnswerWhoWeAreSecondAnswerOne: 'Otorgamos préstamos personales sin garantía a individuos. Nuestra modalidad de ofrecimiento es 100% online y sin papeles. Entregamos préstamos al instante por medio de ',
  helpAnswerWhoWeAreSecondAnswerTwo: ', por nuestra sucursal física en la provincia de Tucumán y por la amplia red de comercios adheridos a los largo de todo el país.',
  helpWhoWeAreThirdQuestion: '¿Tenemos sucursal física?',
  helpAnswerWhoWeAreThirdAnswer: 'Sí, nuestra sucursal está en San Martín 797, San Miguel de Tucumán, en la provincia de Tucumán. Los horarios de atención al público son de Lunes a Viernes de 8.30 a 13.00 hs y de 17.00 a 20.30 hs.',
  helpChannels: 'Canales de atención',
  helpBranch: 'Nuestra sucursal',
  helpBranchInfoOne: 'Si vivís en San Miguel de Tucumán, podes acercarte a nuestras oficinas en ',
  helpBranchInfoTwo: 'Gral. José de San Martín 797, San Miguel de Tucumán. ',
  helpBranchInfoThree: 'Horarios de atención al público de ',
  helpBranchInfoFour: 'lunes a viernes de 9 a 13hs y de 17 a 21hs',
  helpOurWeb: 'nuestra web',
  // helpWt faq
  helpWtFirstQuestion: '¿Quienes pueden pedir préstamos online en Credicuotas?',
  helpWtFirstAnswerOne: 'TODOS. Si necesitas contar con efectivo al instante y queres que la experiencia sea simple, rápida y segura, entonces ingresá a ',
  helpWtFirstAnswerTwo: ' y pedilo. Lo devolves en cuotas fijas mensuales.',
  helpWtSecondQuestion: '¿Cuáles son los requisitos para solicitar un préstamo online?',
  helpWtSecondAnswer: 'Sólo es necesario estar radicado en Argentina con DNI argentino y ser mayor de 18 años, disponer de ingresos demostrables y cuenta bancaria. Te vamos a pedir que tengas un teléfono celular activo y un mail particular. En segundos podés consultar el monto al cual podes acceder. Ingresá a ',
  helpWtThirdQuestion: '¿Qué documentación necesito para solicitar el préstamo personal?',
  helpWtThirdAnswer: 'Te pediremos una foto frente y dorso de tu DNI original, una selfie para validar tu identidad y que tengas una cuenta bancaria a tu nombre.',
  helpWtFourthQuestion: '¿Cuánto dinero puedo pedir por la web de Credicuotas?',
  helpWtFourthAnswerOne: 'El monto de tu préstamo personal será calculado mediante el resultado de tu análisis crediticio. El monto mínimo del préstamo es de $10.000 y podemos prestarte hasta $300.000. Tené en cuenta que si ya sos cliente Credicuotas y estás al día con tus pagos, tu disponible para préstamos aumenta. Si aún no sos cliente ingresá a ',
  helpWtFourthAnswerTwo: ' y pedilo.',
  helpWtFourthAnswerThree: 'y el sistema te indicará el monto que podés solicitar.',
  helpWtFifthQuestion: '¿Cómo hago para saber si puedo pedir un préstamo online?',
  helpWtFifthAnswerOne: 'Usando el simulador de préstamos podrás averiguarlo! Esta herramienta te va a permitir ver un préstamo online desde la primera hasta la última cuota. Ingresá en ',
  helpWtFifthAnswerTwo: ' y calcúlalo.',
  helpWtSixthQuestion: '¿Cuáles son los pasos a seguir para sacar un préstamo personal?',
  helpWtSixthAnswerOne: 'Podes ingresar a ',
  helpWtSixthAnswerTwo: ' y calcular tu préstamo. Una vez que estés decidido, te pediremos que nos brindes un número de celular y un mail para calificarte. Luego seleccionas el monto y cantidad de cuotas fijas mensuales, que es el plazo para que puedas devolver el préstamo.',
  helpWtSixthAnswerThree: 'Te pediremos que cargues una foto del frente y dorso de tu DNI y que te tomes una selfie.',
  helpWtSixthAnswerFour: 'Para poder devolver el préstamos necesitamos que nos brindes una tarjeta de débito y para desembolsar el préstamos nos tenes que dejar el CBU de la cuenta bancaria.',
  helpWtSixthAnswerFive: 'Por último, firmas el contrato de forma digital y esperas unos minutos hasta recibir nuestra llamada de bienvenida, en ese momento tenes disponible tu dinero.',
  helpWtSeventhQuestion: '¿Cómo recibo el dinero de mi préstamo online?',
  helpWtSeventhAnswer: 'Una vez que el crédito es aprobado, vas a recibir una llamada de bienvenida y vamos a validar tu identidad y en forma inmediata vas a disponer de los fondos en la cuenta bancaria que nos informaste.',
  helpWtEighthQuestion: '¿Cómo pago la cuota de mi préstamo personal?',
  helpWtEighthAnswerOne: 'La cuota se debitará mensualmente entre el 1er y 6to día del mes, de la tarjeta de débito que nos informaste cuando te entregamos el préstamo online.',
  helpWtEighthAnswerTwo: 'También cuentas con la opción de abonar tu cuota mediante transferencia o depósito bancario, o en los puntos de cobro digital: mercado pago; o en puntos de cobro físicos: Rapipago, Pago Fácil y Cobro Express con el código de barra del cupón de pago que podes obtenerlo desde nuestro canal de autogestión: ',
  helpWtEighthAnswerThree: 'Ingresá acá.',
  helpWtNinethQuestion: '¿Cuándo vencen las cuotas del préstamo? ',
  helpWtNinethAnswerOne: 'Los préstamos vencen el primer día hábil del mes.',
  helpWtNinethAnswerTwo: 'Si solicitaste que la modalidad de pago del préstamo sea con tarjeta de débito, se cobrará la cuota mensual desde el día 1 al día 6 de cada mes.',
  helpWtNinethAnswerThree: 'Tenemos un beneficio para todos los clientes: Si solicitaste un préstamo a partir del día 21 del mes, la fecha de vencimiento cambia al mes subsiguiente',
  helpWtTenthQuestion: '¿Puedo renovar mi préstamo online?',
  helpWtTenthAnswerOne: 'Si, siempre podrás renovar tu préstamo. Solo considera que es importante que tengas las cuotas de tus préstamos anteriores al día. Ingresá a ',
  helpWtTenthAnswerTwo: ' y averiguá si podés renovar.',
  helpWtEleventhQuestion: '¿Cuál es el contrato que firmo con Credicuotas?',
  helpWtEleventhAnswerOne: 'Te compartimos el modelo de contrato que firmás con nosotros.',
  helpWtEleventhAnswerTwo: '“Contratos de adhesión – Ley 24.240 de Defensa del Consumidor”.',
  // helpWc faq
  helpWcFirstQuestion: '¿Cómo puedo solicitar un préstamos personal para comprar en un comercio?',
  helpWcFirstAnswer: 'Si estás en un comercio adherido a Credicuotas, podes pedirle al vendedor que calcule la oferta que tenemos para vos y  te guiará en los pasos a seguir.',
  helpWcSecondQuestion: '¿Cuales son los productos puedo comprar con el préstamo de Credicuotas?',
  helpWcSecondAnswer: 'Podes comprar cualquier producto que se vende en el comercio que esta adherido a Credicuotas.',
  helpWcThirdQuestion: '¿Puedo financiar el total de la compra con un préstamo Credicuotas?',
  helpWcThirdAnswer: 'Sí, nosotros te calificamos mediante un análisis de tu perfil crediticio y te ofrecemos un monto máximo para el préstamo personal.  Vos decidís qué monto queres llevarte y le informas al vendedor  la cantidad de cuotas fijas en que queres devolverlo.',
  helpWcFourthQuestion: '¿Cuál es el tiempo de aprobación del préstamo en un comercio?',
  helpWcFourthAnswer: 'En el comercio te brindamos una aprobación inmediata y en solo minutos recibirás un llamado del equipo de Credicuotas para darte la bienvenida y así  finalizar el proceso del préstamo.',
  helpWcFifthQuestion: '¿Quién realiza la solicitud del préstamo personal en un comercio?',
  helpWcFifthAnswer: 'El vendedor del comercio será la persona encargada de completar tus datos para solicitar el préstamo personal a Credicuotas.',
  helpWcSixthQuestion: '¿Dónde pago el préstamo de Credicuotas que tomé en el comercio?',
  helpWcSixthAnswerOne: 'En el momento de la compra, el vendedor te va a informar donde debes mensualmente hacer el pago mensual de la cuota del préstamo.',
  helpWcSixthAnswerTwo: 'Te entregará un cupón de pago que te servirá para  todos nuestros canales de cobro como Rapipago, Pago Facil, Mercado Pago, Cobro Express.',
  helpWcSixthAnswerThree: 'También podés abonarla mediante transferencia o depósito bancario, o con una tarjeta de débito en nuestra ',
  helpWcSixthAnswerFour: 'web de clientes.',
  helpWcSeventhQuestion: '¿Cuántos comercios hay adheridos a Credicuotas?',
  helpWcSeventhAnswer: 'Hoy contamos con más de 600 comercios adheridos a los largo de todo el país. Consulta a tu comercio más cercano si ya trabaja con los préstamos de Credicuotas.',
  helpWcEighthQuestion: '¿Puedo renovar mis préstamos 100% online en Credicuotas?',
  helpWcEighthAnswerOne: 'Sí, es posible. Para renovar tu préstamo es importante que tengas las cuotas de tus préstamos al día. Averiguá si podés ',
  helpWcEighthAnswerTwo: 'renovar aquí.',
  // helpBnpl faq
  helpBnplFirstQuestion: '¿Cómo funciona el método compre ahora y pague después?',
  helpBnplFirstAnswerOne: 'Comprá en nuestras tiendas online adheridas y seleccioná Credicuotas como medio de pago.',
  helpBnplFirstAnswerTwo: 'Elegí el plan de pagos que sea más conveniente para vos.',
  helpBnplFirstAnswerThree: 'Para finalizar, mensualmente pagarás tus cuotas.',
  helpBnplSecondQuestion: '¿Puedo comprar ahora y pagar después con Credicuotas?',
  helpBnplSecondAnswer: 'Sí, en nuestra red de tiendas online adheridas podés pagar con Credicuotas de forma rápida y simple. Ingresá aquí para conocerlas.',
  helpBnplThirdQuestion: '¿Puedo pagar en cuotas sin tarjeta de crédito?',
  helpBnplThirdAnswer: 'Sí, con Credicuotas podes pagar tus compras en cuotas con tu tarjeta de débito. La primera cuota la pagas el mes siguiente.',
  helpBnplFourthQuestion: '¿Qué productos puedo comprar con el sistema BNPL?',
  helpBnplFourthAnswer: 'Podés comprar todos los productos disponibles en nuestras tiendas adheridas.',
  helpBnplFifthQuestion: '¿Cuántas compras puedo hacer pagando con BNPL?',
  helpBnplFifthAnswer: 'Podés realizar más de una compra, dependerá de tu límite de crédito disponible.  Si sos cliente de Credicuotas, podrás mejorar tu límite si tus pagos están al día.',
  helpBnplSixthQuestion: '¿Cómo pago las cuotas de mi compra BNPL?',
  helpBnplSixthAnswer: 'Los pagos de las cuotas se realizarán automáticamente con la tarjeta de débito que asociaste.',
  helpBnplSeventhQuestion: '¿Cuáles son los beneficios de comprar ahora y pagar después?',
  helpBnplSeventhAnswer: 'Pagá rápidamente tu compra en cuotas con Credicuotas sin usar tarjeta de crédito.',
  // helpCommerce faq
  helpCommerceFirstQuestion: '¿Cómo hago para adherirme como comercio?',
  helpCommerceFirstAnswerOne: 'Si tu negocio está en el rubro de electrodomésticos, electrónica en general, motocicletas, bicicletas, instrumentos musicales, hogar, colchones y muebles, podes comunicarte con nosotros al mail ',
  helpCommerceFirstAnswerTwo: 'comercios@credicuotas.com.ar',
  helpCommerceFirstAnswerThree: ' y dejanos los datos de tu comercio para que te contactemos.',
  helpCommerceSecondQuestion: '¿Cómo funcionan los préstamos en comercios?',
  helpCommerceSecondAnswer: 'El cliente compra el producto que desea en el comercio adherido a Credicuotas, y paga con un prestamos personal en cuotas fijas. El comercio recibe el monto total de la compra sin ningún tipo de riesgo.',
  helpCommerceThirdQuestion: '¿Qué rubros de comercio no están habilitados para adherirse?',
  helpCommerceThirdAnswer: 'Por el momento no estamos trabajando con comercios de indumentaria, calzados, casas de indumentaria y calzado de deporte, supermercados, decoración, librerías, pinturerías y materiales de construcción.',
  helpCommerceFourthQuestion: '¿Por qué me conviene ofrecer los préstamos de Credicuotas como medio de pago?',
  helpCommerceFourthAnswer: 'Está probado que al ofrecer mayores alternativas de pago al cliente, el volumen de ventas para el comercio se incrementa. Credicuotas es una excelente opción para los clientes que quieren pagar en cuotas fijas sus consumos.',
  helpCommerceFifthQuestion: '¿Cómo se ofrecen los préstamos de Credicuotas en el comercio?',
  helpCommerceFifthAnswer: 'Es simple, al momento del pago, consultale a tu cliente si desea pagar con un préstamo de Credicuotas. Si es así, ingresas a nuestra plataforma y seguís los pasos. En solo 4 pasos el préstamos es otorgado y podes entregar el producto al cliente.',
  // Blog
  blogCredicuotas: 'Blog Credicuotas',
  communityNotes: 'Notas',
  communityDistinguished: 'Últimas notas',
  communityLoadMore: 'Ver notas anteriores',

  // Jobs
  jobs: 'Empleos',
  jobsValues: '4 valores',
  jobsWhy: 'Por qué trabajar con Credicuotas?',
  jobsWhat: 'Qué es Credicuotas?',
  jobsContact: 'Contacto RRHH',

  // Investors
  investorsHighlights: 'Highlights',
  investorsInformation: 'Información financiera',
  investorsRse: 'RSE',

  // Finance User Information
  infoContact: '¿Querés comunicarte con nosotros?',
  inforResponsable: 'Responsables de Atención al Usuario Financiero',
  infoFirstContactUs: '¿Necesitas ayuda personalizada?',
  infoFirstPerson: 'Titular: Eliana Costilla',
  infoSecondPerson: 'Suplente: Nadia Aimi',
  infoClientMail: 'Mail: clientes@credicuotas.com.ar',
  infoTelephoneContactNumber: 'Teléfono de contacto: 0800 888 2733',
  infoAddress: 'Domicilio laboral: Arenales 707, piso 7 (C1054AAF) CABA',
  infoFirstP: 'En caso de no estar de acuerdo con nuestra respuesta, podes comunicarte con la Gerencia Principal de Protección a Usuarios Financieros a través de su sitio: www.usuariosfinancieros.gob.ar',
  infoRights: 'Tus derechos como usuario de servicios financieros:',
  infoChannels: 'Canales de contacto:',
  infoWhatsapp: 'Whatsapp Chat',
  infoWhatsappNumber: '+54 9 11 6457 5091',
  infoMail: 'Mail',
  infoMailClient: 'clientes@credicuotas.com.ar',
  infoTelephone: 'Teléfono',
  infoTelephoneNumber: '0800 888 2733',
  infoFees: 'Consultá nuestros productos y tasas',

  // ConsumerProtection
  consumerTitle: 'Defensa al consumidor',
  consumerAskHere: 'Podés realizar tus consultas aquí',
  consumerFirstP: 'Defensa de las y los Consumidores.',
  consumerHere: 'aquí',
  consumerSecondP: 'Dónde y cómo reclamar si sos residente en C.A.B.A',
  consumerThirdP: 'Consultá la Ley de defensa del consumidor.',
  consumerProductsLink: 'Productos y tasas ',
  consumerNotifications: 'Notificaciones legales',
  consumerLegalInfo: 'Podés contactarte con el área de legales de Credicuotas al correo legales@credicuotas.com.ar a los efectos de notificar:',
  consumerAudience: 'Audiencia de Defensa del Consumidor.',
  consumerJudge: 'Mediación Prejudicial.',
  consumerOmic: 'Audiencia OMIC perteneciente a un Municipio.',
  consumerCoprec: 'Audiencia COPREC',
  consumerTribunal: 'Audiencia Tribunales Arbitrales de Consumo',

  // CancelCredit
  cancelTitle: 'Cancelá tu préstamo',
  cancelTitleBody: '¿Cómo cancelo el préstamo que solicite?',
  cancelCalendar: 'Podés hacerlo siempre y cuando no hayan pasado más de 10 días hábiles desde que te entregamos la plata.',
  cancelClient: 'Llená el formulario de cancelación.',
  cancelContact: 'Te estaremos contactando desde nuestros centro de atención al cliente para avanzar con la devolución.',
  cancelData: 'Completá tus datos',

  // Form Cancel Credit
  formCancelNameTitular: 'Nombre del titular del préstamo',
  formCancelName: 'Nombre',
  formCancelDni: 'DNI',
  formCancelEmail: 'Correo electrónico',
  formCancelWhy: '¿Por qué querés cancelar el préstamo?',
  formCancelSend: 'Enviar',
  formCancelSuccess: 'Hemos recibido tu solicitud',
  formCancelSuccessContact: 'En un plazo de 48 hs estaremos contactándonos para darte respuesta.',
  formCancelThanks: 'Gracias',
  formCancelCredicuotasTeam: 'Equipo de Credicuotas',
  formCancelError: 'Su solicitud no pudo ser enviada correctamente',
  formCancelTryAgain: 'Por favor vuelva a intentarlo',

  // Note Card
  noteCardViewMore: 'Leer más',

  // Blog Note
  blogNoteComeBack: 'Volver',

  // WhatsApp
  wppName: 'Préstamos WhatsApp',
  wppTitle: '¡Pedí tu Préstamo desde WhatsApp!',
  wppButton: 'Comenzar',
  wppClickButton: 'Al hacer click se abrirá automáticamente tu whatsapp, para que nos hables.',
  wppBusiness: 'Whatsapp business, 100% validado',
  wppAsk: 'Consultá tus dudas',
  wppChat: 'Pedilo chateando, lo transferimos en 5 min. a tu cuenta bancaria',
  wppPhoto: 'Solo necesitas sacarte una foto y completar algunos datos',

  // Uber
  uberName: 'Préstamos Uber',
  uberTitle: 'Préstamos exclusivos para socios conductores y socias conductoras del programa Uber Pro',
  uberSubtitle: '100 % online, en el acto y sin vueltas. ¡Te reconocemos tus ganancias en Uber!',
  uberButton: 'Pedilo ahora',
  uberBenefits: 'Con Uber y Credicuotas accedé a distintos beneficios con una mejor oferta con más plata y mejor cuota.',
  uberPartner: 'Si sos socia o socio conductor del programa Uber Pro, Diamante, Platino, Oro y Azul, accederás a distintos beneficios hasta un 25% en el valor de la cuota.',
  uberPaperless: 'Sin filas ni papeleos. Hacé todo desde tu celular y 100% online',
  uberWorks: '¿Cómo funciona?',
  uberStep: 'Seleccioná el monto de tu préstamo, el plazo de pago y obtené tu préstamo en minutos.',
  uberUse: 'Usalo para arreglar tu vehículo, pagar el seguro, llenar el tanque o salir de un apuro.',
  uberRequirements: '¿Lo querés? Lo tenes. Solo necesitas...',
  uberPartners: 'Ser una socia o un socio conductor Diamante, Platino, Oro o Azul del programa Uber Pro',
  uberDocument: 'Tener DNI argentino permanente (no temporal)',
  uberPhone: 'Un celular y un e-mail personal.',

  // Seac
  seacName: 'Carga Virtual',
  seacTitle: 'Préstamos exclusivos para clientes de Carga Virtual',
  seacSubtitle: '100 % online, en el acto y sin vueltas. ¡Te reconocemos tu antigüedad como cliente con Carga Virtual!',
  seacButton: 'Pedilo Ahora',
  seacStepTitle: '¿Cómo pido un préstamo?',
  seacStepText: 'Ingresando tu DNI, ya sabés si tenés un préstamo disponible. Después sacás las fotos, elegís como te pagamos y listo. Lo hicimos bien simple.',
  seacData: 'Datos',
  seacDataText: 'Ingresá tu DNI para saber cuánto te prestamos y mandanos una selfie.',
  seacValidation: 'Validación',
  seacValidationText: 'Por tu seguridad validamos tus datos.',
  seacAccreditation: 'Acreditación',
  seacAccreditationText: '¡Recibí el dinero en tu cuenta en minutos!',
  seacRates: '*En todos los casos, la Tasa Nominal Anual (TNA), la Tasa Efectiva Anual (TEA) y el Costo Financiero Total Nominal Anual (CFTNA) varían según el perfil crediticio del solicitante y plazo de financiación elegido. En todos los casos, la TNA, TEA y el CFT aplicable serán informados claramente al momento de ingresar la solicitud del adelanto o préstamo y antes de su otorgamiento.',
  seacValues: 'El período mínimo para la devolución de un préstamo es de 1 mes y el máximo 18 meses. La TNA de un préstamo varía dependiendo del perfil crediticio del solicitante, siendo la mínima 84% y la máxima 108%. Ejemplo de préstamo: Monto solicitado de $50.000 a 12 meses, TNA (sin IVA): 84%, TEA (sin IVA): 125,2%, CFTNA (con IVA): 168,9%, Cuota: $6.295,10 (+ IVA). Total a pagar: $75.541,19 (+ IVA). El monto mínimo de financiación es de $1.000 y el monto máximo de $50.000. El otorgamiento del préstamo está sujeto a evaluación crediticia. Tipo de Amortización Francés. Dependiendo del perfil de riesgo crediticio: TEA mínima 125,22% + IVA; TEA máxima 181,27% + IVA.',

  // Bind
  bindName: 'Grupo Bind',
  bindTitle: '¡Pedí tu Préstamo desde WhatsApp!',
  bindClic: 'Al hacer click se abrirá automáticamente tu whatsapp, para que nos hables.',
  bindService1: 'Grupo BIND te acerca préstamos en forma ágil y fácil a través de Credicuotas, una empresa de Grupo BIND.',
  bindService2: 'Acercate para mayor informacion a una de las sucursales disponibles o chateanos directamente por Whatsapp.',
  bindService3: 'Si lo querés de forma online ingresa a ',
  bindWebCredicuotas: 'www.credicuotas.com.ar ',
  bindWppBusiness: 'Whatsapp business, 100% validado',
  bindAvailable: 'Consulta tu disponible',
  bindStep: 'Pedilo chateando con un asesor, y coordiná retirarlo en la sucursal',
  bindWait: 'Te esperamos en estas sucursales:',
  bindSanPedro: 'San Pedro',
  bindSanPedroOffice: 'Pellegrini 650',
  bindEzeiza: 'Ezeiza',
  bindEzeizaOffice: 'French 172',
  bindVillaMitre: 'Villa Mitre',
  bindVillaMitreOffice: 'Garibaldi 299',
  bindBahiaBlanca: 'Bahia Blanca',
  bindBahiaBlancaOffice: 'San Martín 302',
  bindTandil: 'Tandil',
  bindTandilOffice: 'San Martín 626',
  bindSalta: 'Salta',
  bindSaltaOffice: '20 de Febrero 63',
  bindTucumanMicro: 'Tucumán Micro',
  bindTucumanMicroOffice: 'San Martín 670',
  bindTucumanCentro: 'Tucumán Centro',
  bindTucumanCentroOffice: 'San Martín 979',
  // Service
  serviceName: 'Pago de facturas',
  serviceTitle: 'Pagá tus facturas con Credicuotas',
  serviceSubtitle: '¿Necesitás pagar tus servicios? Pagá tus facturas con nosotros.',
  serviceButtonBanner: 'Pedilo Ahora',
  serviceStepTitle: '¿Cómo funciona?',
  serviceStepText: 'Por ser un cliente fidelizado de Credicuotas accedés a este beneficio de un préstamo para usarlo como pago del servicio o empresa y lo pagás a los 30 días.',
  serviceStepSubtitle: ' Es muy simple, mirá:',
  serviceCompany: 'Elegí la empresa o servicio a pagar.',
  serviceValidation: 'Validamos automáticamente tu identidad.',
  serviceFinished: 'Listo! Pagaste tu servicio.',
  serviceButtonCredit: 'Simulá tu crédito',
  serviceToCellphoneRecharge: 'También podés hacer una recarga de celular. ',

  // CellphoneRecharge
  rechargeName: 'Recarga de celular',
  rechargeTitle: '¡Ahora podés recargar tu celu!',
  rechargeSubtitle: '¿Te quedaste sin crédito? recargá tu celular con nosotros',
  rechargeButtonBanner: 'Pedilo Ahora',
  rechargeStepTitle: '¿Cómo funciona?',
  rechargeStepText: 'Por ser un cliente fidelizado de Credicuotas accedés a este beneficio de un préstamo para usarlo como recarga de crédito en cualquier celular y lo pagás a los 30 días.',
  rechargeStepSubtitle: 'Es muy simple, mirá:',
  rechargeNumberOne: '1',
  rechargeCompany: 'Elegí la compañia de telefono y el monto a recargar.',
  rechargeNumberTwo: '2',
  rechargeValidation: 'Validamos automáticamente tu identidad.',
  rechargeNumberThree: '3',
  rechargeFinished: 'Listo! Acreditamos al instante la recarga a tu celular.',
  rechargeButtonCredit: 'Simulá tu crédito',
  rechargeHiring: 'La contratación del producto está sujeto a evaluación crediticia por parte de Credicuotas y a tiempos de acreditación de la empresa de telefonía celular.',
  rechargeToService: 'También podés pagar tus facturas. ',

  // Sucursal
  branchesName: 'Sucursales Credicuotas',
  branchesTitle: '¡Pedí tu Préstamo desde WhatsApp!',
  branchesMoney: 'Hasta $ 150.000 en hasta 24 Cuotas',
  branchesClic: 'Al hacer click se abrirá automáticamente tu whatsapp, para que nos hables.',
  branchesButton: 'Comenzar',
  branchesWppBusiness: 'Whatsapp business, 100% validado',
  branchesAvailable: 'Consultá tu disponible.',
  branchesChat: 'Pedilo chateando con un asesor y coordiná retirarlo en la sucursal.',
  branchesWait: 'Te esperamos en San Martin 797, bajo estrictos protocolos para tu seguridad.',

  // Error 404
  errorTitle: 'Página no encontrada',
  errorSubtitle: 'Algo salió mal ¡Pero aún estás muy cerca de tu próximo préstamo personal!',
  errorButton: 'Ir a la página principal',

};

export default i18n;
