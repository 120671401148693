import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Grid, Button, Box, Typography,
} from '@mui/material';
import TextFieldWrapper from './TextFieldWrapper';
import i18n from './i18n';
import { fillMessageWith } from '../../utils/functionUtils';
import serviceZendesk from '../../api/serviceZendesk';
import imageSuccess from '../../images/general/success.svg';
import imageError from '../../images/general/error.svg';
import Loading from './Loading';

const FormCancelCredit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [formResponse, setFormResponse] = useState(true);

  const submitData = async (data) => {
    setIsLoading(true);
    setFormVisible(false);
    const response = await serviceZendesk(data);
    setFormResponse(response);
    setIsLoading(false);
  };

  return (
    <Grid container>
      <Grid item xs={1} md={3} />
      <Grid item xs={10} md={6}>
        { isLoading === true ? (
          <Loading />
        ) : formVisible === true ? (
          <>
            <Box className="gridCard" sx={{ paddingTop: '20px' }}>
              <Typography variant="h3" fontWeight={800} className="lettersGrey" component="h2">
                {i18n.cancelData}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                name: '',
                documentNumber: '',
                mail: '',
                reason: '',
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .required(fillMessageWith(i18n.valueRequired, i18n.formCancelName)),
                documentNumber: Yup.string()
                  .matches(/^[0-9]+$/, i18n.onlyNumbersAllowed)
                  .required(fillMessageWith(i18n.valueRequired, i18n.formCancelDni)),
                mail: Yup.string()
                  .email(i18n.invalidFormat)
                  .required(fillMessageWith(i18n.valueRequired, i18n.formCancelEmail)),
                reason: Yup.string()
                  .required(fillMessageWith(i18n.valueRequired, i18n.formCancelWhy)),
              })}
              onSubmit={(data) => {
                submitData(data);
              }}
            >
              <Form>
                <TextFieldWrapper
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  name="name"
                  label={i18n.formCancelNameTitular}
                  autoComplete={i18n.formCancelNameTitular}
                />
                <TextFieldWrapper
                  margin="normal"
                  required
                  fullWidth
                  id="documentNumber"
                  name="documentNumber"
                  inputProps={{ maxLength: 8 }}
                  label={i18n.formCancelDni}
                  autoComplete={i18n.formCancelDni}
                />
                <TextFieldWrapper
                  margin="normal"
                  required
                  fullWidth
                  id="mail"
                  name="mail"
                  label={i18n.formCancelEmail}
                  autoComplete={i18n.formCancelEmail}
                />
                <TextFieldWrapper
                  margin="normal"
                  required
                  fullWidth
                  id="reason"
                  name="reason"
                  rows={4}
                  label={i18n.formCancelWhy}
                  autoComplete={i18n.formCancelWhy}
                />
                <Box sx={{ paddingTop: '15px', paddingBottom: '55px' }}>
                  <Button type="submit" variant="contained" className="ButtonMagenta buttonCredit">
                    {i18n.formCancelSend}
                  </Button>
                </Box>
              </Form>
            </Formik>
          </>
        ) : (
          formResponse ? (
            <>
              <Box sx={{ paddingTop: '50px' }} />
              <img src={imageSuccess} alt="success" width="200" height="200" />
              <h1>{i18n.formCancelSuccess}</h1>
              <Box className="gridCard">
                <Typography variant="h4" component="h2">{i18n.formCancelSuccess}</Typography>
              </Box>
              <Typography>{i18n.formCancelSuccessContact}</Typography>
              <Typography>{i18n.formCancelThanks}</Typography>
              <Typography>{i18n.formCancelCredicuotasTeam}</Typography>
            </>
          ) : (
            <>
              <Box sx={{ paddingTop: '50px' }} />
              <img src={imageError} alt="error" width="100" height="100" />
              <Box className="gridCard">
                <Typography variant="h4" component="h2">{i18n.formCancelError}</Typography>
              </Box>
              <Typography>
                <a href="cancelacion" rel="noopener noreferrer">
                  {i18n.formCancelTryAgain}
                </a>
              </Typography>
              <Typography>{i18n.formCancelThanks}</Typography>
              <Typography>{i18n.formCancelCredicuotasTeam}</Typography>
            </>
          )
        )}
      </Grid>
      <Grid item xs={1} md={3} />
    </Grid>
  );
};

export default FormCancelCredit;
