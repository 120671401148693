import {
  Grid, Box, Button, Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { route } from '../navigation/routes';
import i18n from '../common/i18n';
import '../../assests/css/cellphoneRecharge.css';
import logoCredicuotas from '../../images/logoCredicuotas.png';
import bannerRecargaCelular from '../../assests/images/recarga/bannerRecargaCelular.png';
import iconContador from '../../assests/images/recarga/contador_bolitas.png';
import iconDocumento from '../../assests/images/recarga/documento.png';
import iconAnotador from '../../assests/images/recarga/anotador.png';
import logoMovistar from '../../assests/images/recarga/logo-movistar.png';
import logoClaro from '../../assests/images/recarga/logo-claro.png';
import logoPersonal from '../../assests/images/recarga/logo-personal.png';
import logoTuenti from '../../assests/images/recarga/logo-tuenti.png';
import {
  RECARGACELULAR, getEnv,
} from '../../api/env';
import LegalFooter from '../common/LegalFooter';

const CellphoneRecharge = () => {
  const RECARGACELULAR_LINK = getEnv(RECARGACELULAR);
  return (
    <>
      <Helmet>
        <title>{i18n.rechargeName}</title>
        <meta name="description" content={i18n.rechargeName} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <header style={{ backgroundColor: 'white' }}>
        <Grid container>
          <Grid item xs={10} md={2} className="gridLogoHeader">
            <img
              src={logoCredicuotas}
              alt={i18n.credicuotas}
              className="logoHeader"
            />
          </Grid>
        </Grid>
      </header>
      {/* Banner */}
      <Grid container className="bannerRecargaCelular" mt={11}>
        <Grid item md={6} xs={12}>
          <Box className="bannerTextRecharge">
            <Typography variant="h4" component="h1" fontWeight={700} color="#383838" className="rechargeTitle">
              {i18n.rechargeTitle}
            </Typography>
            <Box className="rechargeSubtitle">
              <Typography variant="p">{i18n.rechargeSubtitle}</Typography>
            </Box>
          </Box>
          <Box>
            <img src={logoMovistar} alt="logo movistar" className="logoTelefonia" />
            <img src={logoClaro} alt="logo claro" className="logoTelefonia" />
            <img src={logoPersonal} alt="logo personal" className="logoTelefonia" />
            <img src={logoTuenti} alt="logo tuenti" className="logoTelefonia" />
          </Box>
          <Box className="boxButtonRecharge">
            <a
              href={RECARGACELULAR_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit', paddingTop: '2%' }}
            >
              <Button variant="contained" className="ButtonMagentaRecarga">
                {i18n.rechargeButtonBanner}
              </Button>
            </a>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box className="boxBannerImg">
            <img src={bannerRecargaCelular} alt="telefono celular" className="imgBannerRecargaCelular" />
          </Box>
        </Grid>
      </Grid>
      <Grid>
        <Box className="boxSteprecharge">
          <Typography variant="h4" fontWeight={600}>{i18n.rechargeStepTitle}</Typography>
          <Typography variant="p">{i18n.rechargeStepText}</Typography>
          <Typography variant="h6" className="stepSubtitle">{i18n.rechargeStepSubtitle}</Typography>
        </Box>
      </Grid>
      <Box className="boxContainerIcons">
        <Grid container>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxRecargaCelular">
              <img src={iconContador} alt="contador" />
              <Typography fontWeight={600}>{i18n.rechargeNumberOne}</Typography>
              <Typography variant="p">{i18n.rechargeCompany}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxRecargaCelular">
              <img src={iconDocumento} alt="documento" />
              <Typography fontWeight={600}>{i18n.rechargeNumberTwo}</Typography>
              <Typography variant="p">{i18n.rechargeValidation}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxRecargaCelular">
              <img src={iconAnotador} alt="anotador" />
              <Typography fontWeight={600}>{i18n.rechargeNumberThree}</Typography>
              <Typography variant="p">{i18n.rechargeFinished}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item md={2} />
        <Grid item xs={12} md={8} className="boxCenter">
          <Box className="boxCenter">
            <Box className="boxCenter">
              <a
                href={RECARGACELULAR_LINK}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit', paddingTop: '2%' }}
              >
                <Button variant="contained" className="ButtonMagentaRecarga buttonSimulate">
                  {i18n.rechargeButtonCredit}
                </Button>
              </a>
            </Box>
            <Typography variant="caption" className="textContrato">{i18n.rechargeHiring}</Typography>
          </Box>
        </Grid>
        <Grid item md={2} />
      </Grid>
      <Typography variant="h5" fontWeight={600} className="lettersMagentaNew" mb={3} mt={4}>
        {i18n.rechargeToService}
        <a href={route.services} fontWeight={600} className="lettersMagentaNew">
          {i18n.homeLinkHelp}
        </a>
      </Typography>
      <LegalFooter />
    </>
  );
};

export default CellphoneRecharge;
