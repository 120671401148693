import { useState, useEffect } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import { getFeaturedNotes, getOtherNotes } from '../../api/serviceBlog';
import Banner from '../common/Banner';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import blogBanner from '../../assests/images/blog/blogBanner.png';
// import blogBanner from '../../assests/images/banners/blogBanner.jpg';
import RowNotes from '../blog/RowNotes';
import '../../assests/css/blogStyle.css';

const Blog = () => {
  const [distinguishedNotes, setDisitinguishedNotes] = useState([{}]);
  const [generalNotes, setGeneralNotes] = useState([{}]);
  const [minimumId, setMinimumId] = useState(0);

  const getDistinguishedNotes = async () => {
    const notes = await getFeaturedNotes();
    setDisitinguishedNotes(notes);
  };

  function minimumValue(notes) {
    return Math.min(...notes.map((o) => o.note_id));
  }

  const getGeneralNotes = async () => {
    if (minimumId !== 1) {
      const notes = await getOtherNotes(minimumId);
      setGeneralNotes([...generalNotes, notes]);
      setMinimumId(minimumValue(notes));
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getDistinguishedNotes();
    getGeneralNotes();
  }, []);

  return (
    <>
      <Helmet>
        <title>{i18n.metaTitleBlog}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={i18n.metaDescriptionBlog} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Box mt={10}>
        <Banner title={i18n.blogCredicuotas} backgroundImage={bannerImage} iconImage={blogBanner} />
      </Box>
      <Box className="centerTitle">
        <Typography variant="h3" fontWeight={800} component="h2" className="lettersGrey">
          {i18n.communityDistinguished}
        </Typography>
      </Box>
      <RowNotes notes={distinguishedNotes} />
      <Box className="centerTitle">
        <Typography variant="h3" fontWeight={800} component="h2" className="lettersGrey">
          {i18n.communityNotes}
        </Typography>
      </Box>
      {
        generalNotes.length > 1
          && generalNotes.slice(1).map((groupNotes) => (
            <>
              <RowNotes notes={groupNotes.slice(0, 3)} />
              <RowNotes notes={groupNotes.slice(3, 6)} />
              <RowNotes notes={groupNotes.slice(6, 9)} />
            </>
          ))
      }
      {
        minimumId > 1 && (
          <Button variant="contained" onClick={getGeneralNotes} className="ButtonMasNotas">
            {i18n.communityLoadMore}
          </Button>
        )
      }
    </>
  );
};

export default Blog;
