import axios from 'axios';
import { BLOG, getEnv } from './env';

const urlBackend = getEnv(BLOG);

const serviceBlog = async (baseURL) => {
  const notes = await axios.get(baseURL).then((response) => response.data.data).catch((err) => err);
  return notes;
};

export const getFeaturedNotes = async () => {
  const urlFeaturedNotes = `${urlBackend}/api/get_featured_note_preview`;
  return serviceBlog(urlFeaturedNotes);
};

export const getOtherNotes = async (id) => {
  const urlOtherNotes = `${urlBackend}/api/get_note_preview/${id}`;
  return serviceBlog(urlOtherNotes);
};

export const getSpecificNote = async (id) => {
  const urlSpecificNote = `${urlBackend}/api/get_note/${id}`;
  return serviceBlog(urlSpecificNote);
};
