import {
  Box, Grid, Typography, useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import '../../assests/css/bannerStyle.css';

const Banner = ({
  title, subtitle, backgroundImage, iconImage,
}) => {
  const matchesMobile = useMediaQuery('(max-width: 1201px)');
  return (
    matchesMobile ? (
      subtitle ? (
        <Box className="mainBox flexCenter" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <Box className="boxTitlesBanner">
            <Typography variant="h1" fontWeight={700} className="lettersWhiteOnly">{title}</Typography>
            <Box className="spaceBetween widthBoxTypography">
              <Typography variant="h6" fontSize={24} className="lettersWhiteOnly">{subtitle}</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="mainBoxNoSubtitle flexCenter" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <Box className="boxTitlesBanner">
            <Typography variant="h1" fontWeight={700} className="lettersWhiteOnly">{title}</Typography>
          </Box>
        </Box>
      )
    ) : (
      <Box className="mainBox flexCenter" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Grid container>
          <Grid item lg={1.2} />
          <Grid item lg={6} className="gridBannerTitle" mt={4}>
            <Typography variant="h1" fontWeight={700} className="lettersWhiteOnly">{title}</Typography>
            <Box className="boxSubtitle">
              <Typography variant="h5" className="lettersWhiteOnly">{subtitle}</Typography>
            </Box>
          </Grid>
          <Grid item lg={3.8} className="flexCenter">
            {iconImage && <img src={iconImage} alt="icon" />}
          </Grid>
          <Grid item lg={1} />
        </Grid>
      </Box>
    ));
};

Banner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundImage: PropTypes.string,
  iconImage: PropTypes.string,
};

Banner.defaultProps = {
  title: '',
  subtitle: '',
  backgroundImage: '',
  iconImage: '',
};

export default Banner;
