import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getFooterData } from '../../api/services';
import '../../assests/css/legalFooter.css';

const LegalFooter = () => {
  const [footer, setFooter] = useState();
  async function getFooter() {
    try {
      const { data } = await getFooterData('default');
      setFooter(data);
    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  useEffect(() => { getFooter(); }, []);
  return (
    <Box style={{ backgroundColor: 'black', color: 'white' }} className="legalFooterTasasFooter">
      <Grid>
        <Typography variant="p" className="legalFooterTasasText">{footer ? footer.ratesAndExample : ''}</Typography>
        <Box className="spaceBox">
          <Typography variant="p" className="legalFooterValorCFTEA">
            {footer ? footer.totalFinantialCost : ''}
          </Typography>
        </Box>
        <Typography variant="p" className="legalFooterIVA">{footer ? footer.taxesPhrase : ''}</Typography>
        <Box>
          <Typography variant="p" className="legalFooterValores">
            {footer ? footer.finantialCostWithoutTaxes : ''}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default LegalFooter;
