import { TextField } from '@mui/material';
import { useField } from 'formik';
import PropTypes from 'prop-types';

const TextFieldWrapper = ({ name, ...otherProps }) => {
  /* const {
    name,
  } = this.props; */

  const [field, meta] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined',
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return (
    <TextField
      {...configTextfield}
      sx={{
        '& label.Mui-focused': {
          color: '#E61A5F',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: '#E61A5F',
          },
        },
      }}
    />
  );
};

TextFieldWrapper.propTypes = {
  name: PropTypes.string,
};

TextFieldWrapper.defaultProps = {
  name: '',
};

export default TextFieldWrapper;
