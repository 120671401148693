import './App.css';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import AppRouter from './components/navigation/AppRouter';
import {
  getEnv, ENVIRONMENT, GOOGLE_TAG_MANAGER_ID, GOOGLE_ANALYTICS_ID,
} from './api/env';

function App() {
  const ENV = getEnv(ENVIRONMENT);

  if (ENV === 'UAT' || ENV === 'PRD') {
    TagManager.initialize({ gtmId: getEnv(GOOGLE_TAG_MANAGER_ID) });
    ReactGA.initialize(getEnv(GOOGLE_ANALYTICS_ID));
  }
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
