import axios from 'axios';
import { COMMERCE, getEnv } from './env';

const urlBackend = getEnv(COMMERCE);

const getService = async (url) => {
  const data = await axios.get(url).then((response) => response.data).catch((err) => err);
  return data;
};

const getCommerceList = async (provinceId) => {
  const commerce = getService(`${urlBackend}/v1/web/commerces?provinceId=${provinceId}`);
  return commerce;
};

export default getCommerceList;
