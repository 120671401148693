import i18n from '../components/common/i18n';

const fillRegexp = /\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g;

export const fillMessageWith = (message, ...args) => {
  const values = args.reduce((acc, v, i) => {
    acc[i.toString()] = v;
    return acc;
  }, {});
  return message.replace(fillRegexp, (exp, prop) => (values[prop] !== undefined ? values[prop] : ''));
};

export const translateErrorOrDefault = (errorCode, defaultMessage) => {
  const errorMessage = errorCode ? i18n[errorCode] : undefined;
  return errorMessage !== undefined ? errorMessage
    : (defaultMessage !== undefined ? defaultMessage
      : 'Ocurrio un error!');
};
