import {
  Grid, Box, Button, Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import '../../assests/css/uber.css';
import logoUber from '../../assests/images/header/logo-uber.svg';
import logoCredicuotasBlanco from '../../assests/images/header/logo-credicuotas-blanco.svg';
import imgUber from '../../assests/images/uber/uber.png';
import iconMask from '../../assests/images/uber/mask.png';
import iconRombo from '../../assests/images/uber/rombo.png';
import iconParticula from '../../assests/images/uber/particulas.png';
import iconKey from '../../assests/images/uber/key.png';
import iconPath from '../../assests/images/uber/path.png';
import iconStar from '../../assests/images/uber/star.png';
import iconMobile from '../../assests/images/uber/mobile.png';
import {
  UBER, getEnv,
} from '../../api/env';
import LegalFooter from '../common/LegalFooter';

const Uber = () => {
  const UBER_LINK = getEnv(UBER);
  return (
    <>
      <Helmet>
        <title>{i18n.uberName}</title>
        <meta name="description" content={i18n.uberName} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <header style={{ backgroundColor: 'black' }}>
        <Grid container>
          <Grid item xs={10} md={2} className="gridLogoHeader">
            <img
              src={logoUber}
              alt={i18n.credicuotas}
              className="logoHeaderUber"
            />
            <Box className="lineaUber" />
            <img
              src={logoCredicuotasBlanco}
              alt={i18n.credicuotas}
              className="logoHeaderUberCC"
            />
          </Grid>
        </Grid>
      </header>
      {/* Banner */}
      <Grid container className="bannerUber" mt={11}>
        <Grid item xs={12} className="boxTitle">
          <Box>
            <Typography className="uberTitle" component="h1">{i18n.uberTitle}</Typography>
            <Box className="uberSubtitle">
              <Typography variant="p">{i18n.uberSubtitle}</Typography>
            </Box>
            <a
              href={`${UBER_LINK}?user_type=driver`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit', paddingTop: '2%' }}
            >
              <Button variant="contained" className="ButtonNegro">
                {i18n.uberButton}
              </Button>
            </a>
          </Box>
        </Grid>
      </Grid>
      <Grid>
        <Box className="boxImgUber">
          <img src={imgUber} alt="Uber Beneficios" className="imgUber" />
        </Box>
      </Grid>
      <Box className="boxContainerIcons">
        <Grid container>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxUber">
              <img src={iconMask} alt="mano con rombos" className="uberIcons" />
              <Typography variant="h6">{i18n.uberBenefits}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxUber">
              <img src={iconRombo} alt="rombos" className="uberIcons" />
              <Typography variant="h6">{i18n.uberPartner}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxUber">
              <img src={iconParticula} alt="particulas" className="uberIcons" />
              <Typography variant="h6">{i18n.uberPaperless}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid className="uberInfo">
        <Typography variant="h4">{i18n.uberWorks}</Typography>
      </Grid>
      <Grid container className="uberInfo">
        <Grid item xs={12} md={6} className="info">
          <Box className="uberInfoOrange">
            <img src={iconKey} alt="llave de coche" />
          </Box>
          <Box className="uberInfoText">
            <Typography variant="p">{i18n.uberStep}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className="info">
          <Box className="uberInfoGrey">
            <img src={iconPath} alt="hombre conduciendo" />
          </Box>
          <Box className="uberInfoText">
            <Typography variant="p">{i18n.uberUse}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid className="uberInfo">
        <Typography variant="h4">{i18n.uberRequirements}</Typography>
      </Grid>
      <Box className="boxContainerIcons">
        <Grid container>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxUber">
              <img src={iconStar} alt="mano con rombos" className="uberIcons" />
              <Typography variant="h6">{i18n.uberPartners}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxUber">
              <img src={iconMask} alt="mano con rombos" className="uberIcons" />
              <Typography variant="h6">{i18n.uberDocument}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxUber">
              <img src={iconMobile} alt="particulas" className="uberIcons" />
              <Typography variant="h6">{i18n.uberPhone}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LegalFooter />
    </>
  );
};

export default Uber;
