import { useState, useEffect } from 'react';
import {
  Grid, Box, Button, Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getSpecificNote } from '../../api/serviceBlog';
import { BLOG, getEnv } from '../../api/env';
import i18n from '../common/i18n';
import { route } from '../navigation/routes';

const urlBackend = getEnv(BLOG);

const BlogNote = () => {
  const { idNote } = useParams();
  const [note, setNote] = useState([{}]);
  const [urlImage, setUrlImage] = useState([{}]);
  const navigate = useNavigate();

  // chequea si es la url de la web anterior y redirecciona a la url de la misma nota en la nueva web
  const checkOldBlogUrlAndRedirect = (title) => {
    if (window.location.href.indexOf('novedades/notas/') > 1) {
      const keyWords = title.replace(/¿/g, '').replace(/\?/g, '').replace(/\s+/g, '-').toLowerCase();
      navigate(`${route.blog}/${keyWords}-${idNote}`);
    }
  };

  const getNote = async () => {
    const specificNote = await getSpecificNote(idNote);
    checkOldBlogUrlAndRedirect(specificNote.title);
    setNote(specificNote);
    setUrlImage(`${urlBackend}/${specificNote.image}`);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getNote();
  }, []);

  return (
    <>
      <Helmet>
        <title>{note.title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={i18n.footerBlog} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Grid container mt={10}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Box
            sx={{
              marginTop: '5%', border: 1, borderRadius: '16px', borderColor: '#BFBFBF',
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h4" fontWeight={800} component="h1" className="lettersGrey">{note.title}</Typography>
            <Typography variant="h6" component="h2" className="lettersGrey">{note.publish_text}</Typography>
            <img src={urlImage} alt={note.title} width="90%" />
            <div className="content" dangerouslySetInnerHTML={{ __html: note.content }} />
          </Box>
          <Link
            to={{ pathname: route.blog }}
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              className="ButtonMagenta"
              sx={{ marginTop: '3%', marginBottom: '3%' }}
            >
              {i18n.blogNoteComeBack}
            </Button>
          </Link>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default BlogNote;
