export const AUTOGESTION = 'AUTOGESTION';
export const COMMERCE = 'COMMERCE';
export const PRIVACY = 'PRIVACY';
export const CONTRACT = 'CONTRACT';
export const TERMS = 'TERMS';
export const SELFIE_WEB = 'SELFIE_WEB';
export const BLOG = 'BLOG';
export const ORIGINACION_WT = 'ORIGINACION_WT';
export const ENVIRONMENT = 'ENVIRONMENT';
export const ORIGINACION_WT_THIRD_STEP = 'ORIGINACION_WT_THIRD_STEP';
export const SEAC = 'SEAC';
export const UBER = 'UBER';
export const RECARGACELULAR = 'RECARGACELULAR';
export const PAGOSERVICIOS = 'PAGOSERVICIOS';
export const GOOGLE_ANALYTICS_ID = 'GOOGLE_ANALYTICS_ID';
export const GOOGLE_TAG_MANAGER_ID = 'GOOGLE_TAG_MANAGER_ID';

export const getEnv = (key) => window.env[key];
