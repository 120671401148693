import { useState, useEffect } from 'react';
import {
  Box, Grid, Autocomplete, Accordion, AccordionDetails, AccordionSummary, TextField, List, ListItem, ListItemText,
  Divider, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import { getStates } from '../../api/services';
import getCommerceList from '../../api/serviceCommerce';
import i18n from '../common/i18n';
import Banner from '../common/Banner';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import iconBannerWc from '../../assests/images/wc/chicaMoto.png';
import '../../assests/css/wcStyle.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import shopWcIcon from '../../assests/images/wc/tienda-wc.png';
import vendorWcIcon from '../../assests/images/wc/comercio-wc.png';
import biciWcIcon from '../../assests/images/wc/bici-wc.png';
import logoAspen from '../../assests/images/wc/logoAspen.png';
import logoCabral from '../../assests/images/wc/logoCabral.png';
import logoCycles from '../../assests/images/wc/logoCycles.png';
import logoMega from '../../assests/images/wc/logoMega.png';
import logoRoma from '../../assests/images/wc/logoRoma.png';
import logoRh from '../../assests/images/wc/logoRh.png';
import logoUno from '../../assests/images/wc/logoUno.png';
import logoYuhmak from '../../assests/images/wc/logoYuhmak.png';
import shopWc from '../../assests/images/wc/carritoBuscador.svg';
import affiliationCommerceWc from '../../assests/images/wc/adhesion.png';
import moto from '../../assests/images/home/moto.png';
import monitor from '../../assests/images/home/monitor.png';
import guitarra from '../../assests/images/wc/guitarra.png';
import mueble from '../../assests/images/wc/mueble.png';

const PhysicalCommerce = () => {
  const [states, setStates] = useState([]);
  const [defaultProvince, setDefaultProvince] = useState('');
  const [commerceList, setCommerceList] = useState([]);
  const [filterCommerceList, setFilterCommerceList] = useState([]);

  const getProvinces = async () => {
    try {
      const statesResponse = await getStates();
      const { data: statesData } = statesResponse;
      setStates(statesData);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    getProvinces();
  }, []);

  const getCommerce = async (provinceId) => {
    try {
      setCommerceList([]);
      const commerceData = await getCommerceList(provinceId);
      // const commerceData = commerceProvinceExample;
      setCommerceList(commerceData);
      setFilterCommerceList(commerceData);
    } catch (error) {
      console.error('There was an error!', error);
      if (error.response.status === 404) {
        console.log('en catch');
      }
    }
  };

  const handleProvinceStateChange = async (e, state) => {
    setDefaultProvince(state);
    getCommerce(state.id);
  };

  const filterListHandler = (e) => {
    const searchValue = e.target.value;
    const newArray = commerceList.filter(
      (commerce) => commerce.street.toLowerCase().includes(searchValue.toLowerCase())
      || commerce.location.toLowerCase().includes(searchValue.toLowerCase())
      || commerce.fantasyName.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setFilterCommerceList(newArray);
  };

  return (
    <>
      <Helmet>
        <title>{i18n.metaTitleWc}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={i18n.homePhysicalCommerce} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Box mt={10}>
        <Banner
          title={i18n.wcTitle}
          subtitle={i18n.wcSubtitle}
          backgroundImage={bannerImage}
          iconImage={iconBannerWc}
        />
      </Box>
      <Box className="flexCenter">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthTitleHowWc marginTitleUs">
          {i18n.wcHow}
        </Typography>
      </Box>
      <Grid container className="spacePartnersCommerce">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} lg={4} className="flexCenter">
              <Box className="boxCircleWc">
                <img src={shopWcIcon} alt="Tienda" className="imgShopWc" />
              </Box>
              <Box mt={2} className="widthBoxTypography">
                <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                  {i18n.wcComeCommerce}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} className="flexCenter spaceCircleWc">
              <Box className="boxCircleWc">
                <img src={vendorWcIcon} alt="Vendedor" className="imgVendorWc" />
              </Box>
              <Box mt={2} className="widthBoxTypography">
                <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                  {i18n.wcVendorData}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} className="flexCenter spaceCircleWc">
              <Box className="boxCircleWc">
                <img src={biciWcIcon} alt="Tienda" className="imgBiciWc" />
              </Box>
              <Box mt={2} className="widthBoxTypography">
                <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                  {i18n.wcProduct}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Box className="flexCenter widthBoxTypography boxMainPartners">
        <Typography variant="h3" fontWeight={700} className="lettersGrey marginTitleUs">
          {i18n.wcOurCommerce}
        </Typography>
      </Box>
      <Grid container className="spacePartnersCommerce">
        <Grid item xs={0.5} />
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <img src={logoYuhmak} alt="Yuhmak" className="iconCarousel" />
                  <img src={logoAspen} alt="Aspen" className="iconCarousel" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <img src={logoCabral} alt="Cabral Motos" className="iconCarousel" />
                  <img src={logoUno} alt="Uno Motos" className="iconCarousel" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <img src={logoCycles} alt="Cycles" className="iconCarousel" />
                  <img src={logoRh} alt="Rh Motos" className="iconCarousel" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <img src={logoMega} alt="Mega Motos" className="iconCarousel" />
                  <img src={logoRoma} alt="Roma Motos" className="iconCarousel" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} />
      </Grid>
      <Grid container className="gridContainerListCommerce spaceBetween">
        <Grid item xs={12} md={6} className="gridImgShop">
          <img src={shopWc} alt="Carrito de compras" className="imgShop" />
        </Grid>
        <Grid item xs={12} md={6} className="gridItemListCommerce">
          <Box className="boxAutocomplete">
            <Typography variant="h3" fontWeight={700} className="lettersGrey marginTitleUs">
              {i18n.wcFindCommerce}
            </Typography>
            <Autocomplete
              onChange={(e, value) => {
                handleProvinceStateChange(e, value);
              }}
              disableClearable
              value={defaultProvince}
              options={states}
              getOptionLabel={(state) => state?.descripcion || ''}
              getOptionSelected={(option, value) => !value.id || option.id === value.id}
              fullWidth
              className="spaceBetween"
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="province"
                  label={i18n.wcProvince}
                  fullWidth
                />
              )}
            />
            <TextField
              id="outlined-basic"
              label={i18n.wcSearch}
              variant="outlined"
              fullWidth
              sx={{ marginTop: '20px' }}
              onChange={filterListHandler}
            />
            <List
              fullWidth
              className="listScroll"
              sx={{
                bgcolor: '#FFDFA5',
                maxHeight: 300,
                overflow: 'auto',
                marginTop: '40px',
              }}
            >
              {
                commerceList !== [] ? (
                  filterCommerceList.map((commerce) => (
                    <>
                      <ListItem
                        alignItems="flex-start"
                        className="listItemCommerce"
                        key={commerce.fantasyName + commerce.location + commerce.number}
                      >
                        <ListItemText
                          key={commerce.fantasyName + commerce.location + commerce.number}
                          primary={commerce.fantasyName}
                          secondary={
                            `${commerce.street} ${commerce.number},
                            ${commerce.location}, ${defaultProvince.descripcion}`
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" sx={{ marginTop: '5px' }} />
                    </>
                  ))
                ) : (
                  <>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Seleccione una provincia"
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                )
              }
            </List>
          </Box>
        </Grid>
      </Grid>
      <Box className="boxOwnerCommerce">
        <Grid container>
          <Grid item xs={12} lg={6} className="flexCenter">
            <Box className="textLeft titleAFiliateCommerce">
              <Typography variant="h3" fontWeight={700} className="lettersGrey" pt={5}>
                {i18n.wcOwnerCommerce}
              </Typography>
              <Box pt={5}>
                <Typography variant="p" fontSize={20}>
                  {i18n.wcWriteUs}
                </Typography>
              </Box>
              <Box>
                <Typography variant="p" fontSize={20}>
                  {i18n.wcCommerceMail}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} className="flexCenter">
            <img src={affiliationCommerceWc} alt="Adhesión de Comercios" className="imgAffiliationCommerce" />
          </Grid>
        </Grid>
      </Box>
      <Box className="spaceMedium">
        <Typography variant="h3" fontWeight={700} className="lettersMagenta">
          {i18n.wcAreas}
        </Typography>
      </Box>
      <Grid container className="spaceBetween">
        <Grid item xs={0.5} md={1} lg={1} />
        <Grid item xs={11} md={10} lg={10}>
          <Grid container>
            <Grid item xs={12} md={12} lg={5.75}>
              <Grid container className="flexCenter">
                <Grid
                  item
                  xs={5.5}
                  md={4}
                  lg={5.5}
                  className="boxProductsWc boxWcProducts"
                  pb={1}
                  backgroundColor="#FFC761"
                >
                  <img src={moto} alt="moto" className="imgMotoWc" />
                  <Box className="widthBoxTypography">
                    <Typography variant="h6" fontWeight={700} className="lettersGrey">
                      {i18n.wcMoto}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1} md={1} lg={1} />
                <Grid
                  item
                  xs={5.5}
                  md={4}
                  lg={5.5}
                  className="boxProductsWc boxWcProducts"
                  pb={2}
                  backgroundColor="#FFDFA5"
                >
                  <img src={monitor} alt="monitor" className="imgMonitorWc" />
                  <Box className="boxSelectorWidth">
                    <Typography variant="h6" fontWeight={700} className="lettersGrey electroTypographyWc">
                      {i18n.wcElectro}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={0.5} />
            <Grid item xs={12} md={12} lg={5.75}>
              <Grid container className="flexCenter secondBoxesImages">
                <Grid
                  item
                  xs={5.5}
                  md={4}
                  lg={5.5}
                  className="boxProductsWc boxWcProducts"
                  pb={4}
                  backgroundColor="#EFBDDB"
                >
                  <img src={mueble} alt="Mueble" className="imgFurniture" />
                  <Box className="boxSelectorWidth">
                    <Typography variant="h6" fontWeight={700} className="lettersGrey">{i18n.wcFurniture}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={1} md={1} lg={1} />
                <Grid
                  item
                  xs={5.5}
                  md={4}
                  lg={5.5}
                  className="boxProductsWc boxWcProducts"
                  pb={3}
                  backgroundColor="#F9CFD4"
                >
                  <img src={guitarra} alt="Guitarra" className="imgGuitar" />
                  <Box className="boxSelectorWidth">
                    <Typography variant="h6" fontWeight={700} className="lettersGrey">{i18n.wcInstruments}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} md={1} lg={1} />
      </Grid>
      <Box className="flexCenter widthBoxTypography spaceBetween">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxConsumer marginTitleUs">
          {i18n.faq}
        </Typography>
      </Box>
      <Grid container className="spaceBetween" pb={5}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h3">{i18n.wcFirstQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography variant="p" fontSize={18}>{i18n.wcFirstAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h3">{i18n.wcSecondQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography variant="p" fontSize={18}>{i18n.wcSecondAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h3">{i18n.wcThirdQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography variant="p" fontSize={18}>{i18n.wcThirdAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h3">{i18n.wcFourthQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography variant="p" fontSize={18}>{i18n.wcFourthAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default PhysicalCommerce;
