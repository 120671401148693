export const route = {
  home: '/',
  us: '/nosotros',
  physicalCommerce: '/comerciosfisicos',
  buyNowPayLater: '/buynowpaylater',
  help: '/ayuda',
  commerce: '/comercios',
  wt: '/prestamospersonales',
  blog: '/blog',
  jobs: '/empleos',
  investors: '/inversores',
  financeUserInformation: 'informacion-usuario-financiero',
  consumerProtection: 'defensaconsumidor',
  cancelCredit: 'cancelacion',
  branch: '/sucursal',
  whatsapp: '/whatsapp',
  uber: '/uber',
  recharge: '/carga-virtual',
  services: '/servicios',
  error: '/error',
  cellphoneRecharge: '/recarga',
  oldNotes: '/novedades/notas', // esta es la ruta de las notas de la web anterior
};

export default route;
