import {
  Grid, Card, CardContent, Typography, Box,
} from '@mui/material';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import calendar from '../../images/cancelCredit/calendar.svg';
import client from '../../images/cancelCredit/client.svg';
import form from '../../images/cancelCredit/form.svg';
import FormCancelCredit from '../common/formCancelCredit';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import Banner from '../common/Banner';
import '../../assests/css/cancelCreditStyle.css';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const CancelCredit = () => (
  <>
    <Helmet>
      <title>{i18n.metaTitleCancel}</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content={i18n.metaDescriptionCancel} />
      <meta name="robots" content="noindex follow" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <Box mt={10}>
        <Banner title={i18n.cancelTitle} backgroundImage={bannerImage} />
      </Box>
      <Box sx={{ paddingTop: '30px', paddingBottom: '30px' }}>
        <Typography variant="h3" fontWeight={800} className="lettersGrey">{i18n.cancelTitleBody}</Typography>
      </Box>
      <Grid container>
        <Grid item md={0.375} />
        <Grid item xs={12} md={3.75} className="gridCard">
          <Card className="cardBox">
            <CardContent>
              <img src={calendar} alt="calendar" className="imgCard" />
              <Typography variant="body1" className="spaceTypography">
                {i18n.cancelCalendar}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3.75} className="gridCard spaceCard">
          <Card className="cardBox">
            <CardContent>
              <img src={form} alt="form" className="imgCard" />
              <Typography variant="body1" className="spaceTypography">
                {i18n.cancelClient}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3.75} className="gridCard spaceCard">
          <Card className="cardBox">
            <CardContent>
              <img src={client} alt="client" className="imgCard" />
              <Typography variant="body1" className="spaceTypography">
                {i18n.cancelContact}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={0.375} />
      </Grid>
      <FormCancelCredit />
    </ThemeProvider>
  </>
);

export default CancelCredit;
