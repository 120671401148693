import axios from 'axios';
import soapZendesk from './soapZendesk';

const baseURL = 'https://credicuotas.deno.dev/ws_uat/ws_zendesk.asmx';
// const baseURL = 'https://www.credicuotas.com.ar/ws/ws_zendesk.asmx';

const serviceZendesk = async (data) => {
  const zendeskXml = soapZendesk(data);
  let validator;
  await axios
    .post(
      baseURL,
      zendeskXml,
      {
        headers: { 'Content-Type': 'text/xml' },
      },
    )
    .then((response) => {
      if (response.status === 200 && response.data) {
        validator = true;
      }
    })
    .catch(() => {
      validator = false;
    });
  return validator;
};

export default serviceZendesk;
